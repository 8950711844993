import { getIdz, getEventManager } from '../../shared/globals';
import { isRuleConditionMet } from './runner/areRuleConditionsMet';

export default (
  targetingRules,
  startOrUnpauseRunnerIfNoContactOngoing,
  fireRule,
) => {
  //_______________________________________________________________________
  getIdz().ProActif = {
    _datas: targetingRules,

    run: startOrUnpauseRunnerIfNoContactOngoing,

    //________________________________________
    fireRule,

    //________________________________________
    isRuleConditionMet,

    //_______________________________________________________________________________
    //____________________________
    //________________________________________
    match(handler) {
      getEventManager().on('targeting.rule.executed', handler);
    },
  };
};
