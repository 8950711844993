import debounce from '../../../../shared/utils/debounce';

const batchStates = {
  IDLE: 'IDLE', //______________________________
  PLANNED: 'PLANNED',
  RUNNING: 'RUNNING', //________________________
  STACKED: 'STACKED', //_______________________________________________
};

/*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export default (thread, fn, bulkDebounceInterval) => {
  //_________________________________________________________
  let pendingElements = [];

  //________________________________________________________________________________________________________
  let batchState = batchStates.IDLE;

  const planBatchIfIdle = () => {
    batchState = batchStates.PLANNED;

    thread.queueNext(() => {
      batchState = batchStates.RUNNING;

      const currentBatch = pendingElements;
      pendingElements = []; //__________

      return fn(currentBatch).then(() => {
        if (batchState === batchStates.RUNNING) {
          batchState = batchStates.IDLE;
        } else if (batchState === batchStates.STACKED) {
          planBatchIfIdle();
        }
      });
    });
  };

  /*___________________________________________________________________________________*/
  const planBatch = debounce(() => {
    if (batchState === batchStates.IDLE) {
      planBatchIfIdle();
    } else if (batchState === batchStates.RUNNING) {
      batchState = batchStates.STACKED;
    }
  }, bulkDebounceInterval);

  /*__________________________________________________________________________________________________________________________*/
  const batch = (element) => {
    pendingElements.push(element);

    planBatch();
  };

  return {
    batch,
    planBatch,
  };
};
