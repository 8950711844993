import { getIdz, getIsContactOngoing } from '../../../../../../shared/globals';

export default () => {
  /*_____________________________________________________________________________________________________________________________________________________________________________________*/
  async function postTriggeredRules(triggeredRules) {
    const idz = getIdz();
    const isContactOngoing = getIsContactOngoing();
    //___________________________________________
    if (triggeredRules.interactionRules.length === 0) {
      return Promise.resolve([]);
    }

    if (isContactOngoing) {
      return Promise.resolve(
        triggeredRules.interactionRules.map((rule) => rule.id),
      );
    }

    const IDZ_TARGETED_VISITORS_URL = `${idz.targetedVisitors.url}triggered-rules?platform=${idz.platform}`;

    try {
      const response = await fetch(IDZ_TARGETED_VISITORS_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(triggeredRules),
      });
      if (!response.ok) {
        throw new Error(`Unexpected status code ${response.status}`);
      }
      const responseData = await response.json();

      return responseData.authorizedRules;
    } catch (e) {
      return [];
    }
  }

  return {
    postTriggeredRules,
  };
};
