import {
  startPerformanceMeasure,
  PerformanceMarks,
} from '../../../../../../lib/performance';

import { getIdz } from '../../../../../shared/globals';
import {
  isEmbeddedConversationStarterRule,
  isFixedButtonAction,
  isFixedButtonRule,
} from '../../../model/rule/action/actions/FixedButtonAction/FixedButtonAction';
import flatten from '../../../../../shared/utils/flatten';
import flow from '../../../../../shared/utils/flow';
import groupBy from '../../../../../shared/utils/groupBy';
import partition from '../../../../../shared/utils/partition';
import { setDisplayedNotifications } from '../../../../../entry/visitorState/displayedNotifications';
import { setHiddenNotifications } from '../../../../../entry/visitorState/hiddenNotifications';
import { getExecutedScoringRuleIds } from '../../../../../entry/visitorState/executedScoringRuleIds';

export default (fetchStartersService, visibilityObserver) => {
  /*______________________________________*/

  /*______________________________________________________________________________________________________________________________________________*/
  function overrideGlobalsForVisibleRuleExecution(rule) {
    const idz = getIdz();
    idz.callbacks.onRuleStarted({ id: rule.id, label: rule.label });

    //___________________________
    rule.displayed = true;
  }

  /*___________________*/

  /*_____________________________________________________________________________________________________________*/
  function executeQualificationRule(rule) {
    const alreadyExecuted = getExecutedScoringRuleIds();
    if (alreadyExecuted.includes(rule.newId)) {
      return;
    }
    rule.actions?.forEach((action) => {
      action.execute();
    });
  }

  /*____________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function filterWantedInteractionsByFallback(interactionWrappers) {
    return Object.values(
      groupBy(
        interactionWrappers,
        ({ action, rule }) => `${rule.id}-${action.type}`,
      ),
    )
      .map((groupedActions) =>
        groupedActions?.find(
          ({ action, availability }) =>
            (action.isExecuted() && !action.shouldCancel(availability)) ||
            action.shouldExecute(availability),
        ),
      )
      .filter((interaction) => interaction !== undefined);
  }

  /*___________________________________________________________________________________________________________*/
  function filterWantedInteractionsByContainer(interactionWrappers) {
    const [interactionWithoutContainer, interactionWithContainer] = partition(
      interactionWrappers,
      ({ action }) => action.getContainerId() === null,
    );

    const uniqueInteractionsByContainer = Object.values(
      groupBy(interactionWithContainer, ({ action }) =>
        action.getContainerId(),
      ),
    ).map((actionWrappers) => actionWrappers[actionWrappers.length - 1]);

    const uniq = (a) =>
      [...a].sort().filter((item, pos) => !pos || item !== a[pos - 1]); //________________________________________________

    return uniq([
      ...interactionWithoutContainer,
      ...uniqueInteractionsByContainer,
    ]);
  }

  /*________________________________________________________________________________________________________*/
  function filterClosedInteractions(interactionWrappers) {
    return interactionWrappers.filter(({ action }) => !action.isClosed());
  }

  const getActionHiddenCause = (action, availability) =>
    !action.isAvailable(availability)
      ? 'lack of availability'
      : 'unknown detailed reason, but another notification with higher priority should have displayed';

  const emitEventsOnExecute = (wantedInteractions) => {
    const missedFixedButtonRules = wantedInteractions.filter(
      ({ action, availability }) =>
        isFixedButtonAction(action) &&
        !action.isAvailable(availability) &&
        action.isFixedButtonOnline,
    );

    setHiddenNotifications(
      missedFixedButtonRules.map(({ action, rule, availability }) => {
        //_______________________________________________
        action.isFixedButtonOnline = false;
        return {
          engagementRuleId: rule.newId,
          legacyId: rule.id,
          action,
          cause: getActionHiddenCause(action, availability),
        };
      }),
    );
  };

  const emitPipeEventOnCancel = (action, availability, rule) => {
    if (isFixedButtonAction(action)) {
      if (!action.isAvailable(availability) && action.isFixedButtonOnline) {
        //_______________________________________________
        action.isFixedButtonOnline = false;
        setHiddenNotifications([
          {
            engagementRuleId: rule.newId,
            legacyId: rule.id,
            action,
            cause: getActionHiddenCause(action, availability),
          },
        ]);
      }
    } else if (action.isExecuted()) {
      setHiddenNotifications([
        {
          engagementRuleId: rule.newId,
          legacyId: rule.id,
          action,
          cause: getActionHiddenCause(action, availability),
        },
      ]);
    }
  };

  function filterWantedInteractions(interactionsWithAvailability) {
    const wantedInteractionsWithoutDistinctContainers =
      filterWantedInteractionsByFallback(interactionsWithAvailability);

    const wantedInteractions = flow(
      filterWantedInteractionsByContainer,
      filterClosedInteractions,
    )(wantedInteractionsWithoutDistinctContainers).filter(
      ({ action, availability }) => action.shouldExecute(availability),
    );

    return { wantedInteractions, wantedInteractionsWithoutDistinctContainers };
  }

  function formatInteractionWithAvailabilities(rulesWithAvailabilities = []) {
    return flatten(
      rulesWithAvailabilities.map(({ rule, availability }) =>
        (rule.actions || []).map((action) => ({ action, availability, rule })),
      ),
    );
  }

  function executeAction(rule, action, availability) {
    const isFixedButton = isFixedButtonAction(action);

    if (isFixedButton && action.isAvailable(availability)) {
      //_______________________________________________
      action.isFixedButtonOnline = true;
    }

    action.execute(
      availability,
      rule.id,
      rule.newId,
      rule.routingRuleId,
      isFixedButton
        ? action?.parameters?.button?.channel.toLowerCase()
        : undefined,
    );
  }

  function waitContainerInViewport(rule, action) {
    return new Promise((resolve) => {
      visibilityObserver.onceVisible(
        isFixedButtonRule(rule)
          ? [
              action.selectors.onlineCssSelector,
              action.selectors.offlineCssSelector,
              action.selectors.busyCssSelector,
            ]
          : [action.notification.templateAttributes.domTarget],
        resolve,
      );
    });
  }

  async function isStarterEmpty(action, rule, availability) {
    const hasAvailability = action.canBeDisplayed(availability);
    const starters = hasAvailability
      ? await fetchStartersService.fetchConversationStarters(rule)
      : [];
    return starters.length === 0; //__________________________________________________________________
  }

  /*__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function executeInteractionRules(
    rulesWithAvailabilities = [],
    isConversationOngoing = false,
  ) {
    startPerformanceMeasure(PerformanceMarks.TARGETING_RULES_BATCH_EXECUTION);

    const interactionsWithAvailability = formatInteractionWithAvailabilities(
      rulesWithAvailabilities,
    );

    //__________________________________
    const { wantedInteractions, wantedInteractionsWithoutDistinctContainers } =
      filterWantedInteractions(interactionsWithAvailability);

    //________________________
    const executedInteractions = interactionsWithAvailability.filter(
      ({ action }) => action.isExecuted(),
    );
    executedInteractions
      .filter((interaction) => !wantedInteractions.includes(interaction))
      .forEach(({ action, availability, rule }) => {
        emitPipeEventOnCancel(action, availability, rule);
        action.cancel();
      });

    emitEventsOnExecute(wantedInteractions, isConversationOngoing);

    //_________________________
    wantedInteractions.forEach(async ({ action, availability, rule }) => {
      //_______________________________________________________________________________________________
      if (isFixedButtonRule(rule) || isEmbeddedConversationStarterRule(rule)) {
        await waitContainerInViewport(rule, action);
      }

      //__________________________________
      if (
        isEmbeddedConversationStarterRule(rule) &&
        (await isStarterEmpty(action, rule, availability))
      ) {
        return;
      }

      const canSendEvent = isFixedButtonAction(action)
        ? action.isAvailable(availability)
        : !action.isExecuted();

      if (!isConversationOngoing && canSendEvent) {
        setDisplayedNotifications([
          {
            type: action.parameters?.type || action.type.toUpperCase(),
            legacyId: rule.id,
            engagementRuleId: rule.newId,
            notificationId: action.notificationId,
            channels: isFixedButtonAction(action)
              ? [action.parameters.button.channel]
              : action.parameters?.buttons.map((button) => button.channel),
            action,
          },
        ]);
      }

      executeAction(rule, action, availability);
    });

    //___________________
    //_______________________________________________________________________________________________________________________
    //_________________________________________________
    //_____________________
    Object.values(
      groupBy(
        wantedInteractionsWithoutDistinctContainers,
        ({ rule }) => rule?.id,
      ),
    ).forEach(([{ rule }]) => overrideGlobalsForVisibleRuleExecution(rule));

    return rulesWithAvailabilities.map(({ rule }) => rule);
  }

  return {
    executeQualificationRule,
    filterWantedInteractions,
    formatInteractionWithAvailabilities,
    executeInteractionRules,
  };
};
