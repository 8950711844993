/*_____________________________________________________*/
import {
  getEventManager as getDispatcher,
  getIdz,
} from '../../../../../../shared/globals';
import Containers from '../ActionContainers';
import AbstractAction from './AbstractAction';
import getChannel from '../../../channels';
import displayButtonDispatcherFactory from './displayButtonDispatcher';
import { getNotificationById } from './notifications';

import moduleLoader from '../../../../dynamicRegister';
import loadTranslations from '../../../../../../live/src/i18n/loadTranslations';
import flow from '../../../../../../shared/utils/flow';

export default () => {
  const dispatcher = getDispatcher();
  const idz = getIdz();
  const getActionButtonOldLivechatChannel = (button) => {
    if (button.channel === 'VIDEO') {
      return 'visio';
    }

    return button.channel.toLowerCase();
  };

  const isButtonAvailable = (availability) =>
    flow(getActionButtonOldLivechatChannel, getChannel, (channel) =>
      channel.isAvailable({ ...availability, callMeeting: idz.callMeeting }),
    );

  const displayButtonDispatcher = displayButtonDispatcherFactory(dispatcher);

  class TemplatedNotificationAction extends AbstractAction {
    constructor(properties) {
      super(properties);
      this.notification = getNotificationById(this.notificationId);
    }

    isAvailable(availability) {
      const buttons = this.parameters.buttons || [this.parameters.button];

      return buttons.filter(isButtonAvailable(availability)).length > 0;
    }

    shouldExecute(availability) {
      //________________________________________________________________
      //_____________________________________________________________________________________________
      return this.isAvailable(availability) && !this.isCapped();
    }

    //______________________________________________________________
    //_____________________________________________________________________________________
    static canHandle(actionProperties) {
      return (
        actionProperties.type === 'templated_notification' &&
        actionProperties.parameters.type !== 'FIXED' &&
        actionProperties.parameters.type !== 'INVITATION'
      );
    }

    getContainerId() {
      return this.notification.templateType === 'EMBEDDED_CONVERSATION_STARTER'
        ? null
        : Containers.BOTTOM_CORNER;
    }

    async execute(availability, ruleId, engagementRuleId, routingRuleId) {
      super.execute(availability, ruleId, engagementRuleId, routingRuleId);

      const action = this;

      if (
        action.notification.templateType === 'EMBEDDED_CONVERSATION_STARTER'
      ) {
        try {
          const originalStarters =
            action.notification.templateAttributes.starters || [];
          if (originalStarters.length === 0) {
            throw new Error('no starters found');
          }
          const starters = await this.fetchConversationStarters();
          if (starters.length === 0) {
            throw new Error('no starters found');
          }
          action.notification.templateAttributes.starters = starters;
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            //________________________________________
            console.error(error);
          }
        }
      }

      loadTranslations(idz)
        .then(moduleLoader.getNotificationOrchestratorEmitter)
        .then((m) => m.default)
        .then((notificationOrchestratorEmitter) => {
          this.notificationOrchestratorEmitter =
            notificationOrchestratorEmitter;
          const notification = {
            id: action.id,
            notification: action.notification,
            type: action.type,
            parameters: {
              ...action.parameters,

              //____________________________________________________________
              buttons: action.parameters.buttons
                ? action.parameters.buttons.filter(
                    isButtonAvailable(availability),
                  )
                : undefined,
            },
          };
          notificationOrchestratorEmitter.emit('showNotification', {
            notificationData: notification,
            routingRuleId,
            ruleId,
          });
          const actionButtonChannels = action.parameters.buttons.map(
            getActionButtonOldLivechatChannel,
          );
          displayButtonDispatcher.computeAvailabilityAndDispatchDisplay(
            availability,
            actionButtonChannels,
          );
        })
        .catch(() => {
          //___________________________________________________________________
          dispatcher.emit('tag.orchestrator.stop');
        });
    }

    async fetchConversationStarters() {
      const fetchStarters = await import(
        /*_________________________________________*/ './fetchStarters'
      ).then((m) => m.default);
      const starters = await fetchStarters(
        this.notification.templateAttributes.starters,
        idz.chaturl,
        idz.website_id,
        idz.curlang.toUpperCase(),
        this.notification.id,
        this.notification.templateAttributes.knowledgeId,
        this.notification.templateAttributes.knowledgeCustomDataKey,
      );
      return starters;
    }

    cancel() {
      super.cancel();
      this.notificationOrchestratorEmitter?.emit('cancelNotification', {
        notificationId: this.id,
        templateType: this.notification.templateType,
      });
    }
  }

  TemplatedNotificationAction.prototype.visible = true;

  return TemplatedNotificationAction;
};
