import {
  startPerformanceMeasure,
  PerformanceMarks,
  endPerformanceMeasure,
} from '../../../../../../../lib/performance';

import { getIdz } from '../../../../../../shared/globals';
import partition from '../../../../../../shared/utils/partition';
import { getPublicPropertiesStore } from '../../../../../../entry/publicMethods/publicProperties/registerPublicProperties';
import { getExecutedEngagementRules } from '../../../../../../entry/visitorState/executedEngagementRules';
import {
  isEmbeddedConversationStarterRule,
  isFixedButtonAction,
  isFixedButtonRule,
} from '../../../../model/rule/action/actions/FixedButtonAction/FixedButtonAction';
import { getIsFromLightMode } from '../../../../../../entry/visitorContextConfig/executionContext';

export default (
  targetedVisitorsService,
  dispatcher,
  formatInteractionWithAvailabilities,
  filterWantedInteractions,
) => {
  const idz = getIdz();
  const publicPropertiesStore = getPublicPropertiesStore();

  /*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function shouldIncrementDisplayCount(rule, availability, wantedRuleIds) {
    if (isFixedButtonRule(rule)) {
      const { actions } = rule;
      return actions?.some(
        (action) =>
          isFixedButtonAction(action) &&
          action?.canOnlineStateBeDisplayed(availability),
      );
    }

    return wantedRuleIds.includes(rule.id);
  }

  /*___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function shouldIncrementMissedDisplayCount(rule, availability) {
    const { actions } = rule;
    return isFixedButtonRule(rule)
      ? actions?.some(
          (action) =>
            isFixedButtonAction(action) &&
            !action?.channel?.isAvailable(availability) &&
            action?.isOnlineButtonInDom(availability),
        )
      : !rule.hasActionRequiringDisplay(availability) &&
          //_______________________________________________________________________
          actions?.some((action) => action.isVisible() && !action.isCapped());
  }

  /*___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function shouldIncrementCappedDisplayCount(rule, availability) {
    const { actions } = rule;
    return (
      !rule.hasActionRequiringDisplay(availability) &&
      actions?.some((action) => action.isCapped())
    );
  }

  const sendDataPipelineEvents = ({ rule, availability }) => {
    if (shouldIncrementMissedDisplayCount(rule, availability)) {
      dispatcher.emit('dataPipeline.engagementRuleCountMissedDisplay', rule.id);
    }

    if (shouldIncrementCappedDisplayCount(rule, availability)) {
      dispatcher.emit('dataPipeline.engagementRuleCountCappedDisplay', rule.id);
    }
  };

  /*________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function formatTargetedVisitorParams(triggeredRulesWithAvailabilities) {
    const interactionsWithAvailability = formatInteractionWithAvailabilities(
      triggeredRulesWithAvailabilities,
    );
    const { wantedInteractions } = filterWantedInteractions(
      interactionsWithAvailability,
    );
    const wantedRuleIds = wantedInteractions.map(
      (interaction) => interaction.rule.id,
    );

    return {
      visitor: {
        id: idz.vuid,
        websiteId: +idz.website_id,
        //__________________________________________________________________
        sessionId: idz.vStats.sessionId,
      },
      qualificationRules: [],
      interactionRules: triggeredRulesWithAvailabilities?.map(
        (ruleWithAvailability) => {
          const { rule, availability } = ruleWithAvailability;

          //____________________
          //___________________________________________________________________________________________________________
          //_____________________________________________________
          const canBeDisplayed = rule.hasActionRequiringDisplay(availability);

          return {
            id: rule.id,
            isVisibleAndAvailable: rule.hasActionAvailability(availability), //________________________
            canBeDisplayed,
            shouldIncrementTriggerCount: !(
              isFixedButtonRule(rule) || isEmbeddedConversationStarterRule(rule)
            ),
            shouldIncrementDisplayCount: shouldIncrementDisplayCount(
              rule,
              availability,
              wantedRuleIds,
            ),
            shouldIncrementMissedDisplayCount:
              shouldIncrementMissedDisplayCount(rule, availability),
            shouldIncrementCappedDisplayCount:
              shouldIncrementCappedDisplayCount(rule, availability),
            availability,
          };
        },
      ),
    };
  }

  const getAlreadyTriggeredRules = (rulesWithAvailabilities) => {
    const alreadyTriggeredRules = getExecutedEngagementRules()
      .filter((rule) => {
        //____________________________________________________________________
        //___________________________________________________
        const customButtonRules =
          idz.proactifData.interactionRules.filter(isFixedButtonRule);
        //___________________________________________________________________________
        //_______________________________________________________________________________________
        const conversationStarterRules =
          idz.proactifData.interactionRules.filter(
            isEmbeddedConversationStarterRule,
          );

        return ![...customButtonRules, ...conversationStarterRules].some(
          (r) => r.newId === rule.engagementRuleId,
        );
      })
      .map((rule) => {
        const legacyTargetingId = rulesWithAvailabilities.find(
          ({ rule: interactionRule }) =>
            interactionRule.newId === rule.engagementRuleId,
        )?.rule?.id;
        return legacyTargetingId;
      })
      .filter(Boolean);

    return alreadyTriggeredRules;
  };

  const getAuthorizedRuleIds = async (
    toBeDisplayedRulesWithAvailabilities,
    alreadyTriggeredRules,
  ) => {
    //__________________________________________________________________________
    //_________________________________________________________________________
    const triggeredRulesWithAvailabilities =
      toBeDisplayedRulesWithAvailabilities.filter(({ rule }) => {
        return (
          rule.shouldIncrementExecutionCount() &&
          !alreadyTriggeredRules.includes(rule.id)
        );
      });
    const isAllRulesAlreadyTriggered = triggeredRulesWithAvailabilities.every(
      (interactionRule) => alreadyTriggeredRules.includes(interactionRule.id),
    );

    //_______________________________________________________________
    //_________________________________________________________

    const authorizedRuleIds = isAllRulesAlreadyTriggered
      ? alreadyTriggeredRules
      : (
          await targetedVisitorsService.postTriggeredRules(
            formatTargetedVisitorParams(triggeredRulesWithAvailabilities),
          )
        ).concat(...alreadyTriggeredRules);

    return authorizedRuleIds;
  };

  function getPublicPropertiesData(rule, isFixedButton) {
    const action = rule.actions[0];
    return {
      type: isFixedButton
        ? 'CUSTOM_BUTTON'
        : action.parameters?.type || action.type.toUpperCase(),
      id: action.notificationId,
      ruleId: rule.newId,
      channels: isFixedButton
        ? [action.parameters.button.channel]
        : action.parameters?.buttons.map((button) => button.channel),
    };
  }

  function dispatchEngagementNotificationsControlledPublicProperties(
    controlledRules,
  ) {
    const controlledNotificationData = controlledRules.map((rule) =>
      getPublicPropertiesData(rule, isFixedButtonRule(rule)),
    );
    publicPropertiesStore.dispatch(
      'engagementNotifications:controlled',
      controlledNotificationData,
    );
    controlledNotificationData.forEach((data) =>
      publicPropertiesStore.dispatch('engagementNotification:controlled', data),
    );
  }

  /*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  async function getRulesOpportunities(rulesWithAvailabilities) {
    startPerformanceMeasure(
      PerformanceMarks.TARGETING_RULES_BATCH_FETCH_OPPORTUNITY,
    );

    const [
      displayedRulesWithAvailabilities,
      toBeDisplayedRulesWithAvailabilities,
    ] = partition(rulesWithAvailabilities, ({ rule }) => rule?.displayed);

    const alreadyTriggeredRules = getAlreadyTriggeredRules(
      rulesWithAvailabilities,
    );

    const authorizedRuleIds = await getAuthorizedRuleIds(
      toBeDisplayedRulesWithAvailabilities,
      alreadyTriggeredRules,
    );

    const controlledRules = [];

    toBeDisplayedRulesWithAvailabilities.forEach(({ rule, availability }) => {
      const isAuthorized = authorizedRuleIds?.includes(rule.id);
      const hasAvailability = rule.hasActionAvailability(availability);

      if (hasAvailability && !isAuthorized) {
        controlledRules.push(rule);
      }

      if (hasAvailability) {
        idz.callbacks.onTargetingRuleWishToDisplay({
          rule: { id: rule.id },
          vuid: idz.vuid,
          wasExcludedByTest: !isAuthorized,
        });
      }
    });

    dispatchEngagementNotificationsControlledPublicProperties(controlledRules);

    if (!getIsFromLightMode()) {
      toBeDisplayedRulesWithAvailabilities
        .filter(({ rule }) => rule.shouldIncrementExecutionCount())
        .forEach((ruleWithAvailability) => {
          sendDataPipelineEvents(ruleWithAvailability);
        });
    }

    const authorizedAndAvailableRules =
      toBeDisplayedRulesWithAvailabilities.filter(({ rule }) =>
        authorizedRuleIds?.includes(rule.id),
      );

    endPerformanceMeasure(
      PerformanceMarks.TARGETING_RULES_BATCH_FETCH_OPPORTUNITY,
    );

    return [
      ...displayedRulesWithAvailabilities,
      ...authorizedAndAvailableRules,
    ];
  }

  return { getRulesOpportunities, formatTargetedVisitorParams };
};
