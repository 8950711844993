import {
  GlobalIdz,
  LegacyOperator,
} from '../../../../../../../shared/types/globalTypes';
import { ConversationStatus } from './types';
import * as ChatStatus from '../../../../../../../shared/types/chatStatus';
import { AuthorType } from '../../../../../../../chatbox/livechat-helpers/rfcConversation/Messages';
import { getOngoingConversationType } from '../../../../../../../shared/globals';
import { UIAgentAuthor } from '../../../../../../../chatbox/entities/Conversation';
import createStore from '../../../../../../../shared/utils/createStore';
import { getPublicPropertiesStore } from '../../../../../../../entry/publicMethods/publicProperties/registerPublicProperties';
import { ChatboxStatus } from '../../../../../../../shared/types/chatbox';

type FixedButtonState = {
  displayedAvatars: string[];
  chatboxStatus: ChatboxStatus;
  conversationStatus: ConversationStatus;
  unreadMessageCount: number;
  firstVisitorMessage: string | null;
  activeTargetingId: number | null;
};

type FixedButtonStateEvents = {
  refreshDisplayedAvatars: {
    isOngoingConversation: boolean;
    agent?: UIAgentAuthor;
  };
};

export const fixedButtonStore = createStore<
  FixedButtonState,
  FixedButtonStateEvents
>({
  chatboxStatus: 'REDUCED',
  conversationStatus: 'NOT_ONGOING',
  displayedAvatars: [],
  unreadMessageCount: 0,
  firstVisitorMessage: null,
  activeTargetingId: null,
});

export const getIsChannelConversationOngoing = (channelType: string): boolean =>
  getOngoingConversationType() === channelType;

const getIsConversationOngoing = (idz: GlobalIdz) => {
  return (
    idz.chat?.status === ChatStatus.CHATTING ||
    idz.flags.visio_status === '1' ||
    idz.on_call === 1
  );
};

export const fixedButtonStateUpdates = ({
  idz,
  busySelector,
  offlineSelector,
  onlineSelector,
  channelType,
  targetingIds,
}: {
  idz: GlobalIdz;
  busySelector: string;
  offlineSelector: string;
  onlineSelector: string;
  channelType: string;
  targetingIds: number[];
}) => {
  const displayedAvatarListener = ({
    isOngoingConversation,
    agent,
  }: {
    isOngoingConversation: boolean;
    agent?: UIAgentAuthor;
  }) => {
    const { operator, chatboxTemplate } = idz;
    const { avatars } = chatboxTemplate?.templateAttributes || {};
    const allSelectors = [busySelector, offlineSelector, onlineSelector]
      .filter(Boolean)
      .join(',');

    const getAvatarUrls = (): string[] => {
      //________________________
      if (
        agent?.avatar &&
        (agent?.role === AuthorType.BOT ||
          (isOngoingConversation &&
            avatars.duringConversation.human.isUsingDynamicAvatar))
      ) {
        return [agent.avatar];
      }

      if (isOngoingConversation && operator) {
        const legacyOperator = operator as LegacyOperator;

        const botAvatar =
          legacyOperator.avatar ||
          avatars.duringConversation.bot.defaultAvatarUrl;

        const humanAvatar =
          avatars.duringConversation.human.isUsingDynamicAvatar &&
          legacyOperator.avatar
            ? legacyOperator.avatar
            : avatars.duringConversation.human.defaultAvatarUrl;

        const isBot = [agent?.role, legacyOperator.role].includes(
          AuthorType.BOT,
        );

        return [isBot ? botAvatar : humanAvatar];
      }
      //_________________________________
      return avatars?.beforeConversation?.defaultAvatarUrls || [];
    };

    const displayedAvatarDataAttribute = 'data-idz-displayed-avatar';
    const displayedAvatarImageAttribute = 'data-idz-displayed-avatar-image';
    const inertElementAttribute = 'data-idz-inert';

    const avatarUrls = getAvatarUrls();

    [...document.querySelectorAll(allSelectors)].forEach((container) => {
      [
        ...container.querySelectorAll(`[${displayedAvatarImageAttribute}]`),
      ].forEach((imgElm) => {
        imgElm.remove();
      });
    });

    [...document.querySelectorAll(allSelectors)].forEach((container) => {
      [
        ...container.querySelectorAll(`[${displayedAvatarDataAttribute}]`),
      ].forEach((elm) => {
        avatarUrls.forEach((avatarUrl) => {
          const img = document.createElement('img');
          img.src = avatarUrl;
          img.setAttribute(displayedAvatarImageAttribute, '');

          if (elm.hasAttribute(inertElementAttribute)) {
            img.setAttribute(inertElementAttribute, '');
          }

          elm.appendChild(img);
        });
      });
    });
  };

  const chatboxStatusListener = (chatboxStatus: ChatboxStatus) => {
    [...document.querySelectorAll(onlineSelector)].forEach((elm) => {
      elm.setAttribute('data-idz-chatbox-status', chatboxStatus);
    });
  };

  const conversationStatusListener = (
    conversationStatus: ConversationStatus,
  ) => {
    [...document.querySelectorAll(onlineSelector)].forEach((elm) => {
      elm.setAttribute('data-idz-conversation-status', conversationStatus);
    });
  };
  const unreadMessageCountListener = (unreadMessageCount: number) => {
    [...document.querySelectorAll(onlineSelector)].forEach((elm) => {
      elm.setAttribute(
        'data-idz-unread-message-count',
        String(unreadMessageCount),
      );
    });
  };

  const activeTargetingIdListener = (newTargetingId: number | null) => {
    [...document.querySelectorAll(onlineSelector)].forEach((elm) => {
      elm.setAttribute(
        'data-idz-initiator',
        String(targetingIds.includes(newTargetingId!)),
      );
    });
  };

  const publicPropertiesStore = getPublicPropertiesStore();

  return {
    listen: () => {
      const initializeChatboxStatus =
        getIsConversationOngoing(idz) && idz.flags.chatbox_state
          ? idz.flags.chatbox_state
          : 'CLOSED';
      //______________________
      chatboxStatusListener(
        initializeChatboxStatus === 'OPEN' ? 'OPENED' : initializeChatboxStatus,
      );
      conversationStatusListener(
        getIsConversationOngoing(idz) ? 'ONGOING' : 'NOT_ONGOING',
      );
      displayedAvatarListener({
        isOngoingConversation: getIsChannelConversationOngoing(channelType),
      });
      unreadMessageCountListener(0);
      activeTargetingIdListener(Number(idz.flags.proactif_activatedId) || null);

      //_______________________________
      publicPropertiesStore.on('chatbox:status', chatboxStatusListener);
      fixedButtonStore.on('conversationStatus', conversationStatusListener);
      fixedButtonStore.on('refreshDisplayedAvatars', displayedAvatarListener);
      fixedButtonStore.on('unreadMessageCount', unreadMessageCountListener);
      fixedButtonStore.on('activeTargetingId', activeTargetingIdListener);
    },
    stopListening: () => {
      //_____________________
      publicPropertiesStore.off('chatbox:status', chatboxStatusListener);
      fixedButtonStore.off('conversationStatus', conversationStatusListener);
      fixedButtonStore.off('refreshDisplayedAvatars', displayedAvatarListener);
      fixedButtonStore.off('unreadMessageCount', unreadMessageCountListener);
      fixedButtonStore.off('activeTargetingId', activeTargetingIdListener);
    },
  };
};
