import {
  startPerformanceMeasure,
  PerformanceMarks,
  endPerformanceMeasure,
} from '../../../../../../../lib/performance';

import { getIdz } from '../../../../../../shared/globals';
import createRoutingService, {
  getFirstEligibleSkillIdForRules,
} from './routingService';
import { getExecutedEngagementRules } from '../../../../../../entry/visitorState/executedEngagementRules';
import areArraysEqual from '../../../../../../shared/utils/areArraysEqual';

export default () => {
  const idz = getIdz();
  const routingService = createRoutingService();

  /*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function getRuleWithAvailability(
    rulesWithAvailabilities,
    rule,
    ruleAvailabilities,
  ) {
    const ruleWithAvailability = { rule };

    //_____________________________________________________________________
    const ruleAvailability = ruleAvailabilities?.find(
      (_ruleAvailability) => `${_ruleAvailability?.id}` === `${rule.id}`,
    );

    //______________________________________________________________
    //________________________________________________
    //_______________________________________________________________________________________________________
    if (ruleAvailability) {
      ruleWithAvailability.availability = {
        ...ruleAvailability.availability,
      };

      //____________________________________________________________
      //___________________________________________________________
      //________________________________________
      if (
        rule.channel === 'call' ||
        rule.channel === 'all' ||
        rule.channel === 'both'
      ) {
        ruleWithAvailability.availability.call.available =
          idz.call.isEnabled &&
          ((idz.callInstant.enabled &&
            idz.callOffline?.enabled &&
            idz.callOffline?.available) ||
            (!idz.callOffline?.enabled &&
              idz.callInstant.enabled &&
              ruleWithAvailability.availability.call.available));
      }

      rulesWithAvailabilities.push(ruleWithAvailability);
    }

    return rulesWithAvailabilities;
  }

  /*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function reduceRulesToAvailabilities(rules, ruleAvailabilities) {
    return rules.reduce(
      (acc, rule) => getRuleWithAvailability(acc, rule, ruleAvailabilities),
      [],
    );
  }

  /*______________________________________________________________________________________________________________________________________________________________________________________________________*/
  async function getRulesAvailabilities(interactionRules) {
    if (interactionRules.length === 0) {
      return [];
    }
    startPerformanceMeasure(
      PerformanceMarks.TARGETING_RULES_BATCH_FETCH_AVAILABILITY,
    );

    const prefetchedAvailabilities = getExecutedEngagementRules()
      .filter((rule) => {
        //________________________________________________________________________________________________
        //___________________________________________________
        const customButtonRules = idz.proactifData.interactionRules.filter(
          (r) =>
            r.targetingActions[0].parameters?.type === 'FIXED' ||
            r.targetingActions[0].parameters?.type ===
              'EMBEDDED_CONVERSATION_STARTER',
        );
        return !customButtonRules.some(
          (r) => r.newId === rule.engagementRuleId,
        );
      })
      .map((rule) => ({
        id: interactionRules.find(
          (interactionRule) => interactionRule.newId === rule.engagementRuleId,
        )?.id,
        availability: {
          chat: { available: rule.availableChannels.includes('CHAT') },
          call: { available: rule.availableChannels.includes('CALL') },
          video: { available: rule.availableChannels.includes('VIDEO') },
        },
      }))
      .filter((rule) => rule.id !== undefined);

    const areAvailabilitiesPrefetched = areArraysEqual(
      interactionRules.map((interactionRule) => interactionRule.id),
      prefetchedAvailabilities.map(
        (prefetchedAvailability) => prefetchedAvailability.id,
      ),
    );

    if (areAvailabilitiesPrefetched && idz.callOffline?.enabled) {
      idz.forceDep = getFirstEligibleSkillIdForRules(interactionRules);
    }

    const rulesAvailabilities = areAvailabilitiesPrefetched
      ? prefetchedAvailabilities
      : await routingService.checkRulesAvailability(interactionRules);

    if (prefetchedAvailabilities.length > 0) {
      const executedEngagementRules = getExecutedEngagementRules();
      if (idz.callMeeting) {
        idz.callMeeting.available = executedEngagementRules.some(
          (rule) => rule.callAvailability?.callMeetingAvailable,
        );
      }
      if (idz.callInstant) {
        idz.callInstant.available =
          idz.callInstant.enabled &&
          executedEngagementRules.some(
            (rule) => rule.callAvailability?.instantCallAvailable,
          );
      }
      if (idz.callOffline) {
        idz.callOffline.available = executedEngagementRules.some(
          (rule) => rule.callAvailability?.offlineCallAvailable,
        );
      }
    } else if (idz.callInstant) {
      idz.callInstant.available = rulesAvailabilities.some(
        (rule) => rule.availability.call.available,
      );
    }
    const rulesWithAvailabilities = reduceRulesToAvailabilities(
      interactionRules,
      rulesAvailabilities,
    );

    endPerformanceMeasure(
      PerformanceMarks.TARGETING_RULES_BATCH_FETCH_AVAILABILITY,
    );
    return rulesWithAvailabilities;
  }

  //_______________________________________________________________
  //________________________________________________________________
  //____________________________________________________________________________________________
  const addOngoingConversationAvailabilities = (interactionRules) =>
    reduceRulesToAvailabilities(
      interactionRules,
      interactionRules.map((rule) => ({
        id: `${rule.id}`,
        availability: {
          call: { available: true },
          chat: { available: true },
          video: { available: true },
        },
      })),
    );

  return {
    getRulesAvailabilities,
    reduceRulesToAvailabilities,
    addOngoingConversationAvailabilities,
  };
};
