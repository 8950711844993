/*___________________________________________________________________________________________________________________________________________*/

import { DispatcherWithRegistry } from '../../../live/src/event-manager/dispatcherWithRegistry';

let hasVisitorEngaged: boolean = false;

export const getHasVisitorEngaged = (): boolean => hasVisitorEngaged;

export const initVisitorEngagementState = (
  dispatcher: DispatcherWithRegistry,
) => {
  (
    [
      'engagement.showChatboxNotification',
      'engagement.showMessageNotification',
      'notification.clicked',
    ] as const
  ).forEach((event) => {
    dispatcher.on(event, () => {
      hasVisitorEngaged = true;
    });
  });
  (
    [
      'call.callBox.closed',
      'conversation.closedByAgent',
      'engagement.cancelChatbox',
      'engagement.cancelMessage',
      'engagement.chatboxClosed',
    ] as const
  ).forEach((event) => {
    dispatcher.on(event, () => {
      hasVisitorEngaged = false;
    });
  });
};
