export default function omit<
  T extends Record<string, unknown>,
  K extends [...(keyof T)[]],
>(
  obj: T,
  ...keys: K
): {
  [K2 in Exclude<keyof T, K[number]>]: T[K2];
} {
  return Object.keys(obj).reduce(
    (acc, key: keyof typeof obj) => {
      if (keys.includes(key)) return acc;
      return { ...acc, [key]: obj[key] };
    },
    {} as {
      [L in keyof typeof obj]: typeof obj[L];
    },
  );
}
