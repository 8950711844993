import vStats from '../../../../../visitorService/visitorState';
import operators from './operators';

/*__________________________________________________________________________________________*/
export default class AbstractCondition {
  constructor(data) {
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }

  /*_____________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  sanitizeValue(value) {
    return value === null || typeof value === 'undefined'
      ? value
      : `${value}`.toLowerCase();
  }

  /*______________________________________________________________________________________________*/
  getValue() {
    return this.sanitizeValue(vStats[this.criteria.field]);
  }

  /*________________________________________________________________________________________*/
  getTestValue() {
    return this.sanitizeValue(this.value);
  }

  /*__________________________________________________________________________________*/
  isMet() {
    const operator = operators[this.logicalOperator];

    if (!operator) {
      throw new Error(`The operator ${this.logicalOperator} isn't recognized`);
    }

    return operator(this.getValue(), this.getTestValue());
  }

  /*_______________________________________________________________________________________*/
  getBaseType() {
    return undefined;
  }
}
