/*_____________________________________________________________________________________*/

type BrowserName = 'chrome' | 'firefox' | 'safari' | null;
/*_______________________________*/
function guessBrowser(): BrowserName {
  const { navigator } = window;
  if (
    typeof navigator !== 'undefined' &&
    typeof navigator.userAgent === 'string'
  ) {
    if (/Chrome|CriOS/.test(navigator.userAgent)) {
      return 'chrome';
    }
    if (/Firefox|FxiOS/.test(navigator.userAgent)) {
      return 'firefox';
    }
    if (/Safari/.test(navigator.userAgent)) {
      return 'safari';
    }
  }

  return null;
}

/*___________________________________________________________*/
function isRTCPeerConnectionSupported() {
  return typeof window.RTCPeerConnection === 'function';
}

/*_________________________________________________________*/
function isGetUserMediaSupported() {
  const { navigator } = window;
  return (
    navigator.mediaDevices &&
    typeof navigator.mediaDevices.getUserMedia === 'function'
  );
}

/*____________________________________________________________________*/
function isNonChromiumEdge(browser: BrowserName) {
  //_______________________________________________________________
  //_______________
  const { navigator, chrome } = window;
  return (
    browser === 'chrome' &&
    /Edge/.test(navigator.userAgent) &&
    (typeof chrome === 'undefined' || typeof chrome.runtime === 'undefined')
  );
}

const isIOS = (navigator: Navigator) =>
  /(iPhone|iPod|iPad)/i.test(navigator.platform);

function isNonSupportedIOSBrowser(browser: BrowserName) {
  return browser === 'firefox' && isIOS(window.navigator);
}
/*______________________________________________________________________*/
export function isSupported() {
  const browser = guessBrowser();
  return (
    !!browser &&
    !isNonChromiumEdge(browser) &&
    !isNonSupportedIOSBrowser(browser) && //______________________________________________________
    isGetUserMediaSupported() &&
    isRTCPeerConnectionSupported()
  );
}
