import scheduleOnMainJsThread from '../../../lib/scheduleOnMainTread';

import {
  getIdz,
  getEventManager as getDispatcher,
  isChatting,
  shouldReengage,
} from '../../shared/globals';

import * as GDPRConstants from '../../livechat/src/gdpr/constants';

import Rule from './model/rule/Rule';

import createRunner from './runner';
import createOrchestrator from '../../orchestrator/src/loader/index';

import registerOldProActifGlobal from './registerOldProActifGlobal';

import {
  endPerformanceMeasure,
  startPerformanceMeasure,
  PerformanceMarks,
} from '../../../lib/performance';

import {
  getHasVisitorEngaged,
  initVisitorEngagementState,
} from './state/visitorEngaged';
import { initOrchestratorLoadedState } from './state/orchestratorLoaded';

import { setExecutedScoringRuleIds } from '../../entry/visitorState/executedScoringRuleIds';
import { setExecutedEngagementRules } from '../../entry/visitorState/executedEngagementRules';
import { setDisplayedNotifications } from '../../entry/visitorState/displayedNotifications';
import { setHiddenNotifications } from '../../entry/visitorState/hiddenNotifications';

function userIsNotRobot() {
  return !/(ip-label|WatchMouse)/.test(navigator.userAgent);
}

const rulesConfig = {
  interaction: {
    type: '_interaction',
    items: [],
    length: 0,
  },
  qualification: {
    type: '_qualification',
    items: [],
    length: 0,
  },
};

const listenToTargetingEvents = () => {
  const dispatcher = getDispatcher();

  dispatcher.on('targeting.rule.chunkAdded', (ruleChunk) => {
    const interactionRules = rulesConfig.interaction.items;
    const qualificationRules = rulesConfig.qualification.items;
    ruleChunk.content.forEach((rule) => {
      if (ruleChunk.type === rulesConfig.interaction.type) {
        interactionRules.push(rule);
      }
      if (ruleChunk.type === rulesConfig.qualification.type) {
        qualificationRules.push(rule);
      }
    });
    if (
      rulesConfig.interaction.length === interactionRules.length &&
      rulesConfig.qualification.length === qualificationRules.length
    ) {
      endPerformanceMeasure(PerformanceMarks.TARGETING_PARSE_RULES);
      const targetingRules = [...qualificationRules, ...interactionRules];
      dispatcher.emit('targeting.rules.created', {
        interactionRules,
        qualificationRules,
        targetingRules,
      });
    }
  });
};

function chunkRules(rules, size = 20) {
  const res = [];
  for (let i = 0; i < rules.length; i += size) {
    const chunk = rules.slice(i, i + size);
    res.push(chunk);
  }
  return res;
}

function createRules() {
  const idz = getIdz();
  const dispatcher = getDispatcher();
  startPerformanceMeasure(PerformanceMarks.TARGETING_PARSE_RULES);
  const { interactionRules, qualificationRules } = idz.proactifData;
  rulesConfig.interaction.length = interactionRules.length;
  rulesConfig.qualification.length = qualificationRules.length;
  const interactionChunks = chunkRules(interactionRules);
  const qualificationChunks = chunkRules(qualificationRules);

  interactionChunks.forEach((chunk) => {
    scheduleOnMainJsThread(() => {
      dispatcher.emit('targeting.rule.chunkAdded', {
        type: rulesConfig.interaction.type,
        content: chunk.map((ruleData) => new Rule(ruleData)),
      });
    })();
  });

  qualificationChunks.forEach((chunk) => {
    scheduleOnMainJsThread(() => {
      dispatcher.emit('targeting.rule.chunkAdded', {
        type: rulesConfig.qualification.type,
        content: chunk.map((ruleData) => new Rule(ruleData)),
      });
    })();
  });
}

function loadTargeting({
  clearCallbacksState,
  interactionRules,
  qualificationRules,
  spaManager,
  targetingRules,
  visitorService,
}) {
  const dispatcher = getDispatcher();
  const runner = createRunner(
    dispatcher,
    visitorService,
    interactionRules,
    qualificationRules,
  );

  initVisitorEngagementState(dispatcher);
  initOrchestratorLoadedState(dispatcher);

  const getIsContactOngoing = () =>
    (getIdz().chat && isChatting()) || shouldReengage();

  /*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const canBeTargeted = async () => {
    const visitorIdentityService = getIdz().require('visitorIdentityService');
    const cookieConsent = await visitorIdentityService.getCookieConsent();

    return (
      !getIdz().featureFlags['iadvize.engagement.incrementTest'] ||
      cookieConsent === true
    );
  };

  const startOrUnpauseRunnerIfNoContactOngoing = async () => {
    const isTargetable = await canBeTargeted();

    if (isTargetable) {
      if (userIsNotRobot()) {
        dispatcher.emit('targeting.triggered', {
          currentLocation: getIdz().vStats.actualScreen,
          previousLocation: getIdz().vStats.referrer_lastPage,
        });
        return runner.startOrUnpause({
          isOngoingConversation:
            getIsContactOngoing() || getHasVisitorEngaged(),
        });
      }
      return true;
    }
    return false;
  };

  const startOrUnpauseRunnerForFixedButton = async () => {
    const isTargetable = await canBeTargeted();

    if (isTargetable && userIsNotRobot()) {
      runner.runEmbeddedNotificationRules();
    }
  };

  const runRules = async () => {
    const isTargetable = await canBeTargeted();

    if (isTargetable && userIsNotRobot()) {
      runner.run();
    }
  };

  //____________________________________________________________________________
  dispatcher.on('targeting.rule.run', (ruleId) =>
    userIsNotRobot() ? runner.run(ruleId) : true,
  );

  //___________________________________________________________
  dispatcher.on('targeting.pauseAndHide', runner.pauseAndHide);

  dispatcher.on('targeting.startOrUnpause', () => {
    startPerformanceMeasure(PerformanceMarks.TARGETING_RUNNER);
    startOrUnpauseRunnerIfNoContactOngoing();
    endPerformanceMeasure(PerformanceMarks.TARGETING_RUNNER);
  });

  dispatcher.on('targeting.run', () => {
    startPerformanceMeasure(PerformanceMarks.TARGETING_RUNNER);
    runRules();
    dispatcher.emit('targeting.triggered', {
      currentLocation: getIdz().vStats.actualScreen,
      previousLocation: getIdz().vStats.referrer_lastPage,
    });
    endPerformanceMeasure(PerformanceMarks.TARGETING_RUNNER);
  });

  dispatcher.on('notification.clicked', runner.pauseAndHide);

  /*__________________________________________________________________________*/
  dispatcher.on('visitor.navigate.start', async (newScreen) => {
    const actualScreen = getIdz().vStats?.actualScreen || '';
    await runner.stop();
    await visitorService.maintainVisitorState();
    visitorService.updateVisitorStateToScreenChange(newScreen);
    if (newScreen !== actualScreen) {
      dispatcher.emit('visitor.navigated', newScreen);
    }
    if (!getIsContactOngoing() && !getHasVisitorEngaged()) {
      dispatcher.emit('reloadTag');
    }
    clearCallbacksState();
    setExecutedScoringRuleIds([]);
    setExecutedEngagementRules([]);
    setDisplayedNotifications([]);
    setHiddenNotifications([]);
    startOrUnpauseRunnerIfNoContactOngoing();
  });

  scheduleOnMainJsThread(async () => {
    registerOldProActifGlobal(
      targetingRules,
      startOrUnpauseRunnerIfNoContactOngoing,
      runner.fireRule,
    );

    spaManager.startUrlWatcher();
    dispatcher.emit('targeting.bundle.loaded', {
      getIsContactOngoing,
      startOrUnpauseRunnerIfNoContactOngoing,
      startOrUnpauseRunnerForFixedButton,
    });

    endPerformanceMeasure(PerformanceMarks.TARGETING_LOAD);
  })();
}

export default (clearCallbacksState, visitorService, spaManager) => {
  listenToTargetingEvents();
  scheduleOnMainJsThread(createRules)();

  const dispatcher = getDispatcher();
  const idz = getIdz();

  createOrchestrator(
    idz,
    dispatcher,
    visitorService.saveConversationCustomData,
  );

  dispatcher.on(
    'targeting.rules.created',
    ({ interactionRules, qualificationRules, targetingRules }) => {
      scheduleOnMainJsThread(() => {
        loadTargeting({
          clearCallbacksState,
          interactionRules,
          qualificationRules,
          spaManager,
          targetingRules,
          visitorService,
        });
      })();
    },
  );

  return {
    constants: GDPRConstants,
  };
};
