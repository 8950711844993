/*________________________________*/
/*________________________________________*/

import createCondition from './condition';
import createAction from './action';

/*____________________________________________________________________________________________________________________________________________________________________________________________*/
function hashCode(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char; //___________________________________
    hash = hash & hash; //________________________________________________________
  }
  return hash;
}

/*______________________________________________________________________________________________________________________________________________________________________________________*/
export default class Rule {
  constructor(parameters) {
    Object.keys(parameters).forEach((key) => {
      this[key] = parameters[key];
    });

    if (parameters.channel === 'both') {
      this.channel = 'all';
    }

    this.conditions = parameters.targetingConditions.map((rawConditionData) => {
      const { criteria, isVisitorAttribute } = rawConditionData;
      if (criteria && isVisitorAttribute) {
        //_____________________________________________________________________
        criteria.field = `ext_${criteria.field}`;
      }

      return createCondition({
        ...rawConditionData,
        criteria,
      });
    });

    if (parameters.targetingActions) {
      this.actions = parameters.targetingActions
        .map((action) => {
          //________________________________________________________________________________________________
          //_________________________________________________________________________________
          //____________________________________________________
          if (!action.id) {
            switch (action.type) {
              case 'chatbox':
                action.id = `${parameters.id}-chatbox`; //__________________________________________
                break;
              case 'message':
                action.id = `${parameters.id}-${hashCode(action.data.text)}`; //__________________________________________
                break;
              default:
                action.id = action.data.id; //__________________________________________
            }
          }

          return createAction(action);
          //_______________________________
        })
        .filter((action) => !!action);
    }
  }

  /*__________________________________________________________________________________________________________________________*/
  hasActionRequiringDisplay(availability) {
    return this.actions?.some((action) => action.canBeDisplayed(availability));
  }

  hasActionAvailability(availability) {
    return this.actions?.some((action) => action.isAvailable(availability));
  }

  /*__________________________________________________________________________________________________________________________________________________*/
  shouldIncrementExecutionCount() {
    return this.actions?.some((action) =>
      action.shouldIncrementExecutionCount(),
    );
  }
}
