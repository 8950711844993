/*_________________________________________*/

import { getIdz } from '../../../../../../shared/globals';
import AbstractAction from './AbstractAction';

export default () => {
  const idz = getIdz();
  /*__________________________________________________________________________________________________________________________________________*/

  const visitorService = idz.require('visitorService');

  class AbstractVisitorAttributeAction extends AbstractAction {
    /*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
    execute() {
      super.execute();

      const { visitorAttribute } = this.data;
      if (!visitorAttribute) {
        return;
      }

      const attributeName = visitorAttribute.field;
      const storeAttributeValue = visitorService.getCustomData(attributeName);
      const currentAttributeValue =
        typeof storeAttributeValue !== 'undefined' ? +storeAttributeValue : 0;
      const newAttributeValue = this.visitorAttributeModifier(
        currentAttributeValue,
        this.data.value,
      );

      visitorService.setAndSaveCustomData(attributeName, newAttributeValue);
    }
  }

  class IncreaseVisitorAttributeAction extends AbstractVisitorAttributeAction {
    static canHandle(actionProperties) {
      return actionProperties.type === 'increase_visitor_attribute';
    }

    visitorAttributeModifier(existingAttributeValue, modifierValue) {
      return existingAttributeValue + +modifierValue;
    }
  }

  class DecreaseVisitorAttributeAction extends AbstractVisitorAttributeAction {
    static canHandle(actionProperties) {
      return actionProperties.type === 'decrease_visitor_attribute';
    }

    visitorAttributeModifier(existingAttributeValue, modifierValue) {
      return existingAttributeValue - +modifierValue;
    }
  }

  class ModifyVisitorAttributeAction extends AbstractVisitorAttributeAction {
    static canHandle(actionProperties) {
      return actionProperties.type === 'modify_visitor_attribute';
    }

    visitorAttributeModifier(existingAttributeValue, modifierValue) {
      return modifierValue;
    }
  }

  return {
    IncreaseVisitorAttributeAction,
    DecreaseVisitorAttributeAction,
    ModifyVisitorAttributeAction,
  };
};
