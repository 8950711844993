/*______________________________*/
import { getIdz, getEventManager } from '../../../../../../shared/globals';
import Containers from '../ActionContainers';
import AbstractAction from './AbstractAction';
import getChannel from '../../../channels';
import botScenarioPrefetcherFactory from './botScenarioPrefetcher';
import loadTranslations from '../../../../../../live/src/i18n/loadTranslations';
import { getIsOrchestratorLoaded } from '../../../../state/orchestratorLoaded';

export default () => {
  const idz = getIdz();
  const eventManager = getEventManager();
  const botScenarioPrefetcher = botScenarioPrefetcherFactory(idz);

  class MessageAction extends AbstractAction {
    isAvailable(availability) {
      //_________________________________________________________________________
      return getChannel('chat').isAvailable(availability);
    }

    static canHandle(actionProperties) {
      return actionProperties.type === 'message';
    }

    getContainerId() {
      return Containers.BOTTOM_CORNER;
    }

    shouldExecute(availability) {
      return (
        super.shouldExecute(availability) &&
        !MessageAction.hasBeenExecutedInSession()
      );
    }

    execute(availability, ruleId, engagementRuleId, routingRuleId) {
      super.execute(availability, ruleId, engagementRuleId, routingRuleId);
      this.ruleId = ruleId;
      this.routingRuleId = routingRuleId;

      const triggerChatbox = async (prefetchedMessages) => {
        await loadTranslations(idz);
        super.doSideEffectsOnClick(ruleId);
        //________________________________________________________________________________________________
        if (!getIsOrchestratorLoaded()) {
          await eventManager.once('orchestrator.loaded');
        }
        eventManager.emit('engagement.showMessageNotificationBeforeBundle', {
          vuid: idz.vuid,
          actionId: this.id,
          ruleId,
          routingRuleId,
          message: this.data.text,
          prefetchedMessages,
        });
      };

      botScenarioPrefetcher(ruleId, routingRuleId)
        .then(({ data: prefetchedMessages }) =>
          triggerChatbox(prefetchedMessages),
        )
        .catch(() => triggerChatbox());
    }

    /*_______________________________________________________________________________________________________________________________________________________________*/
    recordExecution() {
      idz.flags.proactif_message = true;
      idz.JSONStore.save({ proactif_message: true });
    }

    static hasBeenExecutedInSession() {
      return !!idz.flags.proactif_message;
    }

    shouldIncrementExecutionCount() {
      return !MessageAction.hasBeenExecutedInSession();
    }

    cancel() {
      super.cancel();
      eventManager.emit('targeting.action.message.cancel', {
        vuid: idz.vuid,
        actionId: this.id,
        ruleId: this.ruleId,
        routingRuleId: this.routingRuleId,
      });
    }
  }

  MessageAction.prototype.visible = true;

  return MessageAction;
};
