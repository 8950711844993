import {
  DispatcherWithRegistry,
  dispatcherWithRegistryFactory,
  namespaces,
} from '../../../live/src/event-manager/dispatcherWithRegistry';
import getConversationIdFromQueryParams from '../../../live/src/getConversationIdFromQueryParams';
import {
  startPerformanceMeasure,
  PerformanceMarks,
} from '../../../../lib/performance';
import { incrementNotificationCappingIfNecessary } from '../../../targeting/src/runner/notificationCapping';
import { GlobalIdz, PresenceType } from '../../../shared/types/globalTypes';
import { UUID } from '../../../shared/types/utils';
import {
  isChatting,
  getIsFromMail,
  shouldReengage,
} from '../../../shared/globals';
import loadTranslations from '../../../live/src/i18n/loadTranslations';

const getOrchestratorLoaderModule = () =>
  import(
    /*______________________________________________*/
    './orchestratorLoader'
  );

export default function (
  idz: GlobalIdz,
  dispatcher: DispatcherWithRegistry,
  saveConversationCustomData: (conversationId: UUID) => Promise<void>,
) {
  const bindOnChatboxClose = () => {
    if (
      !idz.featureFlags ||
      idz.featureFlags[
        'iadvize.engagement.run-targeting-after-chatbox-close'
      ] !== false
    ) {
      //__________________________________________________
      idz.orchestrator.onResetChatbox(({ isUserIntended }) => {
        if (!isUserIntended) {
          return;
        }

        incrementNotificationCappingIfNecessary();

        idz.ProActif.run(); //___________________
      });
    }
  };

  //____________________________________
  const getConversationId = () => idz.flags.conversationId;

  dispatcher.once(
    'targeting.bundle.loaded',
    async ({
      getIsContactOngoing,
      startOrUnpauseRunnerIfNoContactOngoing,
      startOrUnpauseRunnerForFixedButton,
    }) => {
      const conversationId = getConversationId();
      const pSaveCustomData =
        isChatting() && conversationId
          ? saveConversationCustomData(conversationId)
          : Promise.resolve();

      if (getIsFromMail()) {
        if (shouldReengage()) {
          dispatcher.emit('dataPipeline.chatboxTrackingEvent', {
            event:
              'conversations.chatbox.cameBackFromEmailAndConversationFinalClosed',
            properties: {
              conversationId: getConversationIdFromQueryParams() || undefined,
            },
          });
        } else {
          dispatcher.emit('dataPipeline.chatboxTrackingEvent', {
            event:
              'conversations.chatbox.cameBackFromEmailAndConversationSnoozed',
            properties: {
              conversationId: getConversationIdFromQueryParams() || undefined,
            },
          });
        }
      }

      const loadOrchestrator = async () => {
        await loadTranslations(idz);
        const { orchestratorLoader } = await getOrchestratorLoaderModule();
        startPerformanceMeasure(PerformanceMarks.TARGETING_LOAD_ORCHESTRATOR);
        dispatcher.once('orchestrator.loaded', bindOnChatboxClose);
        orchestratorLoader(
          dispatcherWithRegistryFactory(namespaces.orchestrator),
        );
      };

      dispatcher.once('targeting.rule.fired', () => {
        loadOrchestrator();
      });

      await pSaveCustomData;

      const isContactOngoing = getIsContactOngoing();
      if (!isContactOngoing) {
        startOrUnpauseRunnerIfNoContactOngoing();
      } else {
        startOrUnpauseRunnerForFixedButton();
        loadOrchestrator();
      }

      dispatcher.on('targeting.fixedButton.rules.run', () => {
        const timer = setTimeout(() => {
          startOrUnpauseRunnerForFixedButton();
          clearTimeout(timer);
        }, 500);
      });
    },
  );

  dispatcher.on('visitor.navigated', () => {
    if (isChatting()) {
      const sendPresence = () =>
        dispatcher.emit('conversation.sendPresence', PresenceType.DND);

      const conversationId = getConversationId();

      if (!conversationId) {
        return;
      }

      saveConversationCustomData(conversationId).then(
        sendPresence,
        sendPresence,
      );
    }
  });

  dispatcher.on('targeting.action.chatbox.cancel', (event) => {
    if (!isChatting()) {
      dispatcher.emit('engagement.cancelChatbox', event);
    }
  });
  dispatcher.on('targeting.action.message.cancel', (event) => {
    if (!isChatting()) {
      dispatcher.emit('engagement.cancelMessage', event);
    }
  });

  //______________________________________
  (
    [
      'conversation.chat.started',
      'conversation.video.created',
      'conversation.call.started',
    ] as const
  ).forEach((event) => {
    dispatcher.on(event, () => {
      const conversationId = getConversationId();

      if (!conversationId) {
        return;
      }

      saveConversationCustomData(conversationId);
    });
  });

  dispatcher.on('tag.orchestrator.stop', async () => {
    const { orchestratorUnloader } = await getOrchestratorLoaderModule();
    window.iAdvize.orchestrator?.resetChatbox({ isUserIntended: false });

    //_____________________________________________________________________________
    //___________________________________________________________
    setTimeout(() => {
      orchestratorUnloader(
        dispatcherWithRegistryFactory(namespaces.orchestrator),
      );
    }, 0);
  });
  dispatcher.on('tag.orchestrator.start', async () => {
    const { orchestratorLoader } = await getOrchestratorLoaderModule();
    dispatcher.once('orchestrator.loaded', bindOnChatboxClose);
    orchestratorLoader(dispatcherWithRegistryFactory(namespaces.orchestrator));
  });
}
