/*__________________________________________________________________________________________________________________________________________*/
function update() {
  const idz = window.iAdvize;

  const params = {
    findop: idz.website_id,
    u: idz.vuid,
    dep: idz.forceDep,
    s: idz.chat.status,
  };

  const queryParam = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  return new Promise((resolve) => {
    //___________________________________________
    //__________________________________________________________________
    //_______________________________________________________________
    if (document.visibilityState && document.visibilityState === 'hidden') {
      setTimeout(function () {
        update().then(resolve);
      }, 1000);

      return;
    }

    fetch(`${idz.chaturl}rpc/poll?${queryParam}`)
      .then((response) => {
        return response.json().then((availabilities) => {
          resolve(availabilities);
        });
      })
      .catch(() => {
        setTimeout(() => {
          update().then(resolve);
        }, 10000);
      });
  });
}

export default { update };
