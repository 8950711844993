/*______________________________________*/

import { GlobalIdz } from '../../shared/types/globalTypes';
import throttle from '../../shared/utils/throttle';

//____________________________________________________________________________________________________
const CALL_MEETING_AVAILABILITY_ALWAYS = 1;
const CALL_MEETING_AVAILABILITY_NO_CALL_INSTANT_AVAILABILITY = 2;
const CALL_MEETING_AVAILABILITY_PLANNING = 3;

type FindOpReturn = {
  callOffline?: {
    available: boolean;
  };
  callMeeting?: {
    available: boolean;
  };
};

const invokeCallbacks = throttle(
  (
    callbacks: GlobalIdz['callbacks'],
    callAvailabilities: Partial<
      Record<
        'callOffline' | 'callMeeting' | 'callInstant',
        { available?: boolean; enabled?: boolean }
      >
    >,
  ) => {
    callbacks.onStatusChanged(callAvailabilities);
    callbacks.onCallStatusChanged(callAvailabilities);
  },
  500,
);

const getCallMeetingAvailaibility = (
  callMeetingMode: GlobalIdz['callMeeting']['mode'],
  callInstantAvailability: boolean,
  callOfflineAvailability: boolean | undefined,
  callOfflineEnabled: boolean,
  callMeetingAvailaibility = false,
): boolean => {
  switch (Number(callMeetingMode)) {
    case CALL_MEETING_AVAILABILITY_NO_CALL_INSTANT_AVAILABILITY:
      return callOfflineEnabled
        ? !callOfflineAvailability
        : !callInstantAvailability;
    case CALL_MEETING_AVAILABILITY_ALWAYS:
    case CALL_MEETING_AVAILABILITY_PLANNING:
    default:
      return callMeetingAvailaibility;
  }
};

//____________________________________________________
export default (idz: GlobalIdz, obj: FindOpReturn) => {
  const callInstantAvailability = {
    enabled: idz.callInstant.enabled,
    available: idz.callInstant.available || false,
  };

  const callMeetingAvailability = {
    enabled: idz.callMeeting?.enabled,
    available: getCallMeetingAvailaibility(
      idz.callMeeting?.mode,
      callInstantAvailability.available,
      obj.callOffline?.available,
      idz.callOffline.enabled,
      obj.callMeeting?.available,
    ),
  };

  const callOfflineAvailability = {
    enabled: idz.callOffline?.enabled,
    available: obj.callOffline?.available,
  };

  idz.callMeeting ??= {} as GlobalIdz['callMeeting'];
  idz.callOffline ??= {} as GlobalIdz['callOffline'];
  idz.callInstant ??= {} as GlobalIdz['callInstant'];

  idz.callMeeting.available = callMeetingAvailability.available;
  idz.callOffline.available = callOfflineAvailability.available;
  idz.callInstant.available = callInstantAvailability.available;

  if (idz.chat.status !== 2) {
    const callAvailabilities = {
      callInstant: callInstantAvailability,
      callMeeting: callMeetingAvailability,
      callOffline: callOfflineAvailability,
    };

    invokeCallbacks(idz.callbacks, callAvailabilities);
  }
};
