/*______________________________________*/
export const selectAll = (selector: string): HTMLElement[] => {
  if (!selector) {
    return [];
  }

  try {
    return [...window.document.querySelectorAll(selector)] as HTMLElement[];
  } catch (e) {
    return [];
  }
};
const selectorOrElementToElementsArray = (
  selectorOrElement: string | HTMLElement,
): HTMLElement[] => {
  if (!selectorOrElement) {
    return [];
  }

  if (typeof selectorOrElement === 'string') {
    return selectAll(selectorOrElement);
  }
  return [selectorOrElement];
};

export const on = (
  selectorOrElement: string | HTMLElement,
  eventName: string,
  eventHandler: EventListenerOrEventListenerObject,
) => {
  selectorOrElementToElementsArray(selectorOrElement).forEach((element) =>
    element.addEventListener(eventName, eventHandler),
  );
};

export const hide = (selector: string): void =>
  selectAll(selector).forEach((element) => {
    const previousDisplay = element.getAttribute('data-previous-display-state');
    if (!previousDisplay) {
      element.setAttribute(
        'data-previous-display-state',
        element.style.display,
      );
    }
    element.style.display = 'none';
  });

export const show = (selector: string): void =>
  selectAll(selector).forEach((element) => {
    const display = element.getAttribute('data-previous-display-state') || '';
    if (display === 'none') {
      element.style.display = '';
      return;
    }

    /*___________________________________________*/
    element.style.display = display;
  });

export const off = (
  selectorOrElement: string | HTMLElement,
  eventName: string,
  eventHandler: EventListenerOrEventListenerObject,
) => {
  selectorOrElementToElementsArray(selectorOrElement).forEach((element) =>
    element.removeEventListener(eventName, eventHandler),
  );
};
