/*___________________________________________________________________________________________________________________________________________*/

import { DispatcherWithRegistry } from '../../../live/src/event-manager/dispatcherWithRegistry';

let isOrchestratorLoaded: boolean = false;

export const getIsOrchestratorLoaded = (): boolean => isOrchestratorLoaded;

export const initOrchestratorLoadedState = (
  dispatcher: DispatcherWithRegistry,
) => {
  dispatcher.on('orchestrator.loaded', () => {
    isOrchestratorLoaded = true;
  });
};
