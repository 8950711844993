import getActionTypes from './actions';

/*_________________________________________________________________________________________________________________________________________________________________________________________________*/
/*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/

export default function createAction(data) {
  const actionTypes = getActionTypes();
  const Action = actionTypes?.find((actionType) => actionType.canHandle(data));

  if (!Action) {
    //____________________________________________________
    //________________________________________
    console.warn(`Unknown targeting rule action type ${data.type}`);
    return null;
  }

  return new Action(data);
}
