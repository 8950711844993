type ValueOf<T> = T[keyof T];

export default function groupBy<T = unknown>(
  arr: T[],
  criteria: ((item: T) => ValueOf<T>) | keyof T,
) {
  //________________________________________________________________
  return arr.reduce((obj: any, item) => {
    const key =
      typeof criteria === 'function' ? criteria(item) : item[criteria];
    //___________________________________________________
    if (!obj.hasOwnProperty(key)) {
      //_______________________________________________
      obj[key] = [];
    }
    obj[key].push(item);
    return obj;
  }, {});
}
