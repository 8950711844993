import {
  startPerformanceMeasure,
  endPerformanceMeasure,
  PerformanceMarks,
} from '../../../../../lib/performance';
import { incrementNotificationCappingIfNecessary } from '../notificationCapping';
import {
  getEventManager as getDispatcher,
  getIdz,
} from '../../../../shared/globals';
import ruleTypeEnum from '../../model/rule/ruleTypeEnum';
import createThread from '../../util/thread';
import createCancelableManager from './cancelableManager';
import createBatcher from './batcher';
import createAvailabilityHandler from './handlers/AvailabilityHandler';
import createTargetedVisitorsService from './handlers/OpportunityHandler/targetedVisitorsService';
import createExecutionHandler from './handlers/ExecutionHandler';
import createOpportunityHandler from './handlers/OpportunityHandler/OpportunityHandler';
import flatten from '../../../../shared/utils/flatten';
import flow from '../../../../shared/utils/flow';
import noop from '../../../../shared/utils/noop';
import { setExecutedEngagementRules } from '../../../../entry/visitorState/executedEngagementRules';
import fetchStartersService from './handlers/fetchStartersService';
import { getFixedButtonVisibilityObserver } from '../fixedButtonVisibilityObserver';
import { isFixedButtonAction } from '../../model/rule/action/actions/FixedButtonAction/FixedButtonAction';

function unionBy(...arrays) {
  const iteratee = arrays.pop();

  if (Array.isArray(iteratee)) {
    return [];
  }
  return flatten([...arrays]).filter(
    (
      (set) => (o) =>
        set.has(iteratee(o)) ? false : set.add(iteratee(o))
    )(new Set()),
  );
}

export default (batchDebounceTimeout = 200, activeTabCheckInterval = 1000) => {
  const idz = getIdz();
  const dispatcher = getDispatcher();
  //___________________________________________________
  //__________________________________________________________________
  const firedRulesStack = {
    qualificationRules: [],
    interactionRules: [],
  };

  const availabilityHandler = createAvailabilityHandler();
  const targetedVisitorsService = createTargetedVisitorsService();
  const executionHandler = createExecutionHandler(
    fetchStartersService(idz),
    getFixedButtonVisibilityObserver(),
  );
  const opportunityHandler = createOpportunityHandler(
    targetedVisitorsService,
    dispatcher,
    executionHandler.formatInteractionWithAvailabilities,
    executionHandler.filterWantedInteractions,
  );

  const thread = createThread();
  const cancelableManager = createCancelableManager(activeTabCheckInterval);

  const checkThenExecuteInteractionRules = async (
    interactionRules,
    isConversationOngoing = false,
  ) => {
    const rulesWithAvailabilities = isConversationOngoing
      ? availabilityHandler.addOngoingConversationAvailabilities(
          interactionRules,
        )
      : await availabilityHandler
          .getRulesAvailabilities(interactionRules)
          .then(opportunityHandler.getRulesOpportunities);

    setExecutedEngagementRules(
      interactionRules.map(({ newId, id, routingRuleId, actions }) => {
        const [action] = actions;
        return {
          authorized: true,
          availableChannels: isFixedButtonAction(action)
            ? [action.parameters.button.channel]
            : action.parameters?.buttons.map((button) => button.channel),
          engagementRuleId: newId,
          callAvailability: {
            callMeetingAvailable: Boolean(idz.callMeeting?.available),
            offlineCallAvailable: Boolean(idz.callOffline?.available),
            instantCallAvailable: Boolean(idz.callInstant?.available),
          },
          legacyId: id,
          capped: action.isCapped(),
          routingRuleId,
        };
      }),
    );

    const executedRules = executionHandler.executeInteractionRules(
      rulesWithAvailabilities,
      isConversationOngoing,
    );

    if (
      !isConversationOngoing &&
      executedRules.some((rule) => rule.displayed)
    ) {
      dispatcher.emit('targeting.engine.checkAvailability');
    }

    return executedRules;
  };

  //_______________________________________________________
  const fireRulesBulkFactory = () =>
    cancelableManager.wrapInWaitingActiveTabCancelable(
      (matchedInteractionRules) => {
        startPerformanceMeasure(PerformanceMarks.TARGETING_RULES_BATCH);

        //___________________________________
        //_______________________________________________________________________________________
        //____________________________________________________________________
        const interactionRules = firedRulesStack.interactionRules
          .filter((rule) => rule.displayed)
          .concat(matchedInteractionRules);

        return checkThenExecuteInteractionRules(interactionRules).then(
          (executedRules) => {
            firedRulesStack.interactionRules = unionBy(
              firedRulesStack.interactionRules,
              executedRules,
              (rule) => rule?.id,
            );

            //______________________________________________________
            executedRules?.forEach((rule) =>
              dispatcher.emit('targeting.rule.executed', rule),
            );
            endPerformanceMeasure(
              PerformanceMarks.TARGETING_RULES_BATCH_EXECUTION,
            );
            endPerformanceMeasure(PerformanceMarks.TARGETING_RULES_BATCH);
          },
        );
      },
    );

  let fireRuleBatcher = createBatcher(
    thread,
    fireRulesBulkFactory(),
    batchDebounceTimeout,
  );

  /*____________________________________________________________________________________________________________________________________________________*/
  //_______________________________________________
  function fireRule(rule) {
    //_______________________________________________________________________
    if (
      !rule ||
      firedRulesStack.interactionRules?.some(
        (firedRule) => firedRule?.id === rule.id,
      ) ||
      firedRulesStack.qualificationRules.some(
        (firedRule) => firedRule?.id === rule.id,
      )
    ) {
      return true;
    }

    //____________________________________
    if (window.document.querySelector('#idzc2c')) {
      return false;
    }

    rule.executed = true; //__________________________________________

    if (rule.type === ruleTypeEnum.QUALIFICATION) {
      //________________________________
      executionHandler.executeQualificationRule(rule);
      firedRulesStack.qualificationRules = unionBy(
        firedRulesStack.qualificationRules,
        [rule],
        (_rule) => _rule?.id,
      );
    } else {
      //___________________________________
      fireRuleBatcher.batch(rule);
    }
  }

  /*________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const fireEmpty = () => fireRuleBatcher.planBatch();

  /*_____________________________________________________________________________________________*/
  function untriggerRule(rule) {
    if (!rule) {
      return;
    }
    rule.executed = false; //__________________________________________

    if (rule.type === ruleTypeEnum.QUALIFICATION) {
      firedRulesStack.qualificationRules =
        firedRulesStack.qualificationRules.filter((r) => r.id !== rule.id);
    } else {
      firedRulesStack.interactionRules =
        firedRulesStack.interactionRules.filter((r) => r.id !== rule.id);

      //________________________________________________
      rule.displayed = false; //__________________________________________
    }

    //__________________________________________________________
    //__________________________________________________________________
    rule.actions?.forEach((action) => action.untrigger());

    return rule; //__________________________________________
  }

  const untriggerQualificationRules = () => {
    firedRulesStack.qualificationRules.forEach((rule) => {
      rule.executed = false; //__________________________________________
      rule.actions?.forEach((action) => action.untrigger());
    });
    firedRulesStack.qualificationRules = [];
  };

  function filterDisplayedInvitationRules(rulesWithAvailabilities) {
    return rulesWithAvailabilities?.reduce((acc, { rule, availability }) => {
      //_________________________________________________________________________________________________________
      const filteredActions = rule?.actions?.filter(
        (action) =>
          !(
            action?.parameters?.type === 'INVITATION' &&
            action?.isAvailable(availability)
          ),
      );

      if (filteredActions.length > 0) {
        acc.push({
          rule: {
            ...rule,
            actions: filteredActions,
          },
          availability,
        });
      }
      return acc;
    }, []);
  }

  /*____________________________________________________________________________________________________________________________________________________________________________*/
  function fireReRun() {
    return thread.queueNext(
      cancelableManager.wrapInWaitingActiveTabCancelable(() => {
        const rulesToReRun = firedRulesStack.interactionRules.filter(
          (rule) => rule.displayed,
        );

        if (rulesToReRun.length === 0) {
          return Promise.resolve();
        }

        return availabilityHandler
          .getRulesAvailabilities(rulesToReRun)
          .then(filterDisplayedInvitationRules)
          .then(executionHandler.executeInteractionRules)
          .then(() => dispatcher.emit('targeting.engine.checkAvailability'));
      }),
    );
  }

  /*_____________________________________________________________________________________________________________________*/
  const cancelPending = () => {
    cancelableManager.cancelAll();

    fireRuleBatcher = createBatcher(
      thread,
      fireRulesBulkFactory(),
      batchDebounceTimeout,
    );

    return thread.queueNext(noop);
  };

  /*________________________________________________________________________________________________________________________________________________________________________________________*/
  const hideAllNotifications = () => {
    cancelableManager.cancelAll();

    const res = thread.queueNext(() => {
      flow(
        (rules) => rules.filter((r) => r.displayed),
        (rules) => flatten(rules.map((r) => r.actions)),
        (actions) =>
          actions.filter(
            (a) =>
              a.isExecuted() &&
              a.isVisible() &&
              a.notification?.templateType !== 'FIXED' &&
              a.notification?.templateType !== 'EMBEDDED_CONVERSATION_STARTER',
          ),
        (actions) => actions.forEach((a) => a.cancel()),
      )(firedRulesStack.interactionRules);
    });

    fireRuleBatcher = createBatcher(
      thread,
      fireRulesBulkFactory(),
      batchDebounceTimeout,
    );

    return res;
  };

  /*_________________________________________________________________________________*/
  function closeNotification(actionId, ruleId) {
    const action = flow(
      (rules) => flatten(rules.map((rule) => rule.actions)),
      (actions) => actions?.find((_action) => _action?.id === actionId),
    )(firedRulesStack.interactionRules);
    if (!action) {
      return;
    }
    action.close();

    incrementNotificationCappingIfNecessary();

    dispatcher.emit('notification.closed', {
      action,
      ruleId,
    });
  }

  dispatcher.on('notification.close', closeNotification);
  return {
    fireRule,
    fireEmpty,
    fireReRun,

    cancelPending,
    untriggerQualificationRules,

    untriggerRule,
    hideAllNotifications,

    checkThenExecuteInteractionRules,
  };
};
