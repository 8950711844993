import getVisitorAttributeActions from './VisitorAttributeActions';
import getTemplatedNotificationAction from './TemplatedNotificationAction';
import getTemplatedNotificationInvitationAction from './TemplatedNotificationInvitationAction';
import getChatboxAction from './ChatboxAction';
import getMessageAction from './MessageAction';
import getFixedButtonAction from './FixedButtonAction/FixedButtonAction';

export default () => {
  const {
    IncreaseVisitorAttributeAction,
    DecreaseVisitorAttributeAction,
    ModifyVisitorAttributeAction,
  } = getVisitorAttributeActions();

  //_____________________________________
  return [
    IncreaseVisitorAttributeAction,
    DecreaseVisitorAttributeAction,
    ModifyVisitorAttributeAction,
    getTemplatedNotificationAction(),
    getTemplatedNotificationInvitationAction(),
    getChatboxAction(),
    getMessageAction(),
    getFixedButtonAction(),
  ];
};
