/*__________________________*/
import scheduleOnMainJsThread from '../../../../lib/scheduleOnMainTread';
import debounce from '../../../shared/utils/debounce';

import { areRuleConditionsMet } from './areRuleConditionsMet';
import createFireRuleFlow from './fireRuleFlow';
import createCancelableManager from './fireRuleFlow/cancelableManager';
import { createFixedButtonVisibilityObserver } from './fixedButtonVisibilityObserver';
import * as ruleRunScheduler from './ruleRunScheduler';

import { getHasVisitorEngaged } from '../state/visitorEngaged';
import { getIdz, getOngoingConversationType } from '../../../shared/globals';
import { setExecutedEngagementRules } from '../../../entry/visitorState/executedEngagementRules';
import partition from '../../../shared/utils/partition';
import {
  isEmbeddedConversationStarterAction,
  isFixedButtonAction,
} from '../model/rule/action/actions/FixedButtonAction/FixedButtonAction';

const sortBy = (predicate) => (a, b) => {
  if (predicate(a) > predicate(b)) return 1;
  return predicate(b) > predicate(a) ? -1 : 0;
};

const stateEnum = {
  STOPPED: 'STOPPED',
  STARTED: 'STARTED',
  PAUSED: 'PAUSED',
};

/*_________________________________________________________________________________________________________*/
function rankRuleOrder(rule) {
  if (rule.channel === 'all') {
    /*_________________________________________________________________________________________________________________________________*/
    return 1;
  }

  return 0;
}

const fixedButtonVisibilityObserver = createFixedButtonVisibilityObserver();

export default (
  dispatcher,
  visitorService,
  interactionRules,
  qualificationRules,
) => {
  let state = stateEnum.STOPPED;

  const cancelableManager = createCancelableManager();
  const fireRuleFlow = createFireRuleFlow();
  const sortedTargetingRules = [
    ...qualificationRules,
    ...[...interactionRules].sort(sortBy(rankRuleOrder)),
  ];

  /*_________________________________*/
  const updateCustomData = () => {
    if (typeof window.idzCustomData === 'object') {
      Object.entries(idzCustomData).forEach(([key, value]) => {
        if (typeof value !== 'undefined' && value !== null) {
          visitorService.setCustomData(key, value);
        }
      });
    }
  };

  const checkRules = (rules) => {
    const [successRules, failedRules] = partition(rules, areRuleConditionsMet);

    //_________________________________________________________________________________________________________________
    failedRules.forEach((rule) => {
      scheduleOnMainJsThread(() => {
        if (rule.executed) {
          fireRuleFlow.untriggerRule(rule);
        }
        //_____________________________________________________________________
        ruleRunScheduler.scheduleRuleRunIfNeeded(rule, () => runRule(rule.id));
      })();
    });

    //__________________________________________________
    successRules.forEach((rule) => {
      scheduleOnMainJsThread(() => {
        fireRuleFlow.fireRule(rule);
        dispatcher.emit('targeting.rule.fired');
      })();
    });
  };

  /*_________________________________________________________________________*/
  const runRule = (ruleId) => {
    updateCustomData();
    //________________________________
    if (state !== stateEnum.STARTED) {
      return true;
    }
    //______________________________
    if (getHasVisitorEngaged()) {
      return true;
    }
    //______________________________
    const rulesToCheck = sortedTargetingRules?.filter(
      (rule) => rule.id == ruleId, //_______________________________
    );

    checkRules(rulesToCheck);

    //________________________________________
    return rulesToCheck;
  };

  const runEmbeddedNotificationRules = () => {
    updateCustomData();
    const fixedButtonRules = sortedTargetingRules.filter((rule) => {
      const action = rule.targetingActions[0];

      return (
        action !== undefined &&
        (isFixedButtonAction(action) ||
          isEmbeddedConversationStarterAction(action))
      );
    });

    const ongoingConversationType = getOngoingConversationType();
    const isConversationOngoing = ongoingConversationType !== undefined;
    const idz = getIdz();
    const activatedRuleId = Number(idz.flags.proactif_activatedId);

    //_________________________________________________________________
    //____________________________________
    const rulesToExecute = isConversationOngoing
      ? fixedButtonRules.filter((rule) => {
          if (rule.channel !== ongoingConversationType) {
            return false;
          }
          const targetingAction = idz.proactifData.interactionRules.find(
            (r) => r.id === rule.id,
          )?.targetingActions?.[0];
          const notificationId = targetingAction?.notificationId;
          const notificationTemplate =
            idz.proactifData.notifications[notificationId];
          if (
            notificationTemplate.templateType === 'FIXED' &&
            notificationTemplate?.templateAttributes?.hideReducedChatbox !==
              true
          ) {
            return true;
          }
          return typeof rule.id === 'number' && rule.id === activatedRuleId;
        })
      : fixedButtonRules.filter(areRuleConditionsMet);

    fireRuleFlow.checkThenExecuteInteractionRules(
      rulesToExecute,
      isConversationOngoing,
    );
  };

  const runAllRules = () => {
    updateCustomData();
    //________________________________
    if (state !== stateEnum.STARTED) {
      return true;
    }

    checkRules(sortedTargetingRules);

    //________________________________________
    return sortedTargetingRules;
  };

  const run = (ruleId) => (ruleId ? runRule(ruleId) : runAllRules());

  //________________________________________
  const fireRule = (rule) => {
    fireRuleFlow.fireRule(rule);
  };

  /*____________________________________________________________________________________________________________________________________________________________________________*/
  const reRun = () => {
    setExecutedEngagementRules([]);
    if (state === stateEnum.STARTED && !getHasVisitorEngaged()) {
      return fireRuleFlow.fireReRun();
    }

    return Promise.resolve();
  };

  const startOrUnpause = ({ isOngoingConversation = false }) => {
    if (isOngoingConversation) {
      runEmbeddedNotificationRules();
      return;
    }
    if (state === stateEnum.STOPPED) {
      state = stateEnum.STARTED;

      /*___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      fireRuleFlow.untriggerQualificationRules();

      /*___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      fireRuleFlow.fireEmpty();

      /*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      dispatcher.emit('targeting.routing.service.polling.reset');

      runAllRules();
    } else if (state === stateEnum.PAUSED) {
      state = stateEnum.STARTED;
      reRun();
    }
  };

  const pauseAndHide = () => {
    if (state === stateEnum.STARTED) {
      state = stateEnum.PAUSED;
      fireRuleFlow.hideAllNotifications();
    }
  };

  /*______________________________________________________________________________________________________________________________________________________*/
  const stop = () => {
    const currentState = state;
    state = stateEnum.STOPPED;

    if (currentState === stateEnum.STARTED) {
      cancelableManager.cancelAll();
      fixedButtonVisibilityObserver.unsubscribeAll();
      ruleRunScheduler.clear();
      return fireRuleFlow.cancelPending();
    }

    return Promise.resolve();
  };

  //____________________________________________________________________________________________
  dispatcher.on('targeting.engine.checkAvailability', debounce(reRun, 30000));

  return {
    state,
    fireRule,
    run,
    startOrUnpause,
    pauseAndHide,
    stop,
    runEmbeddedNotificationRules,
  };
};
