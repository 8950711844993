import scheduleOnMainTread from '../../../lib/scheduleOnMainTread';

import { getEventManager as getDispatcher } from '../../shared/globals';
import loadModule from './module';
import initializeTargeting from './initialize';

export default (clearCallbacksState, visitorService, spaManager) => {
  const dispatcher = getDispatcher();

  dispatcher.on('targeting.bundle.initialized', () => {
    loadModule(clearCallbacksState, visitorService, spaManager);
  });

  scheduleOnMainTread(() => {
    initializeTargeting();
    dispatcher.emit('targeting.bundle.initialized');
  })();
};
