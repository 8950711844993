/*_________________________________________________________*/
import getJSONStore from '../../../../../../shared/JSONStore';
import { isNotificationDisplayCapped } from '../../../../runner/notificationCapping';
import { getIdz } from '../../../../../../shared/globals';
import { fixedButtonStore } from './FixedButtonAction/fixedButtonStore';
import {
  setExecutionState,
  getExecutionState,
} from '../../../../state/executionState';
/*__________________________________________________________________________________________*/
class AbstractAction {
  /*___________________________________________________________________________________________*/
  constructor(properties) {
    this.executed = false;
    this.closed = false;

    Object.keys(properties).forEach((key) => {
      this[key] = properties[key];
    });
  }

  /*_____________________________________________________________________________________________________________________________________________________________*/
  //_______________________________________________________________
  static canHandle(actionProperties) {
    return false;
  }

  /*_________________________________________________________________________________________________________________________________________________________________*/
  getContainerId() {
    return null;
  }

  /*__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  //_______________________________________________________________
  execute(availability, ruleId, engagementRuleId, routingRuleId) {
    this.executed = true;
    this.recordExecution();

    if (engagementRuleId) {
      setExecutionState({
        ...getExecutionState(),
        [engagementRuleId]: Date.now(),
      });
    }
  }

  /*_____________________________________________________________*/
  recordExecution() {}

  /*________________________________________________________________________________________________________________*/
  doSideEffectsOnClick(ruleId) {
    const idz = getIdz();
    //_______________________________________
    idz.flags.proactif_activatedId = ruleId;
    fixedButtonStore.dispatch('activeTargetingId', ruleId);

    //_________________________________________
    getJSONStore().saveData({
      //_______________________________________
      proactif_activatedId: idz.flags.proactif_activatedId,
    });
  }

  /*_________________________________________________________________________________________*/
  cancel() {
    this.executed = false;
  }

  /*________________________________________________________________________________________________________*/
  close() {
    if (!this.isVisible()) {
      throw new Error('A non-visible action cannot be closed');
    }

    this.closed = true;
    this.cancel();
  }

  /*____________________________________*/
  untrigger() {
    this.closed = false;
    if (this.executed) {
      this.cancel();
    }
  }

  /*______________________________________________________________________________________________________________________________________________________________________________*/
  //_______________________________________________________________
  isAvailable(availability) {
    return true;
  }

  /*________________________________________________________________________*/
  isExecuted() {
    return this.executed;
  }

  /*______________________________________________________________________________*/
  isClosed() {
    return this.closed;
  }

  /*______________________________________________________________________________________*/
  isVisible() {
    return this.visible;
  }

  /*______________________________________________________________________________________________________________________________________________*/
  shouldIncrementExecutionCount() {
    return true;
  }

  /*________________________________________________________________________________________________________________________________________________________________________________________________________*/
  canBeDisplayed(availability) {
    return this.isAvailable(availability) && this.isVisible();
  }

  isCapped() {
    return this.overrideCapping ? false : isNotificationDisplayCapped();
  }

  /*__________________________________________________________________________________________________________________________________________________________________________________________*/
  shouldExecute(availability) {
    return (
      !this.isExecuted() && this.isAvailable(availability) && !this.isCapped()
    );
  }

  /*________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  shouldCancel(availability) {
    return this.isExecuted() && !this.isAvailable(availability);
  }
}

AbstractAction.prototype.visible = false;

export default AbstractAction;
