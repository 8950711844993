/*______________________________________*/
export default function (window, idz) {
  idz.util = {
    //___________________________________________________________________
    detectDoctype() {
      return { xhtml: '', version: '', importance: '', loose: 0 };
    },
    //___________________________________________________________________
    //_______________________________________
    load_css(filename) {
      const dochead = document.head;
      const newcss = document.createElement('link');
      newcss.setAttribute('rel', 'stylesheet');
      newcss.setAttribute('type', 'text/css');
      newcss.setAttribute('href', filename);
      dochead.appendChild(newcss);
    },
    nl2br(str) {
      const breakTag = '<br/>';
      return `${str}`.replace(/([^>]?)(\r\n|\n\r|\n|\r)/g, `$1${breakTag}`);
    },
    htmlspecialchars(str) {
      /*_______________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      if (typeof str === 'string') {
        str = str.replace(/&/g, '&amp;');
        /*_________________________*/
        str = str.replace(/"/g, '&quot;');
        str = str.replace(/'/g, '&#039;');
        str = str.replace(/</g, '&lt;');
        str = str.replace(/>/g, '&gt;');
        str = str.replace(/\\/g, '&#92;');
      }
      return str;
    },
    /*_________________________________________________________________________________________________________________________________________________________*/
    htmlSimpleEscape(str) {
      if (typeof str === 'string') {
        str = str.replace(/</g, '&lt;');
        str = str.replace(/>/g, '&gt;');
      }
      return str;
    },
    string: {
      /*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      padLeft(value, padding, size) {
        let newstr = value;

        while (newstr.length < size) {
          newstr = padding + newstr;
        }

        return newstr;
      },
    },

    date: {
      /*________________________________________________________________________________________________________________________________________________________________________________________________________*/
      getMonthName(date) {
        if (!date) {
          return '';
        }

        const month = date.getMonth();

        switch (month) {
          case 0:
            return idz.T('january');
          case 1:
            return idz.T('february');
          case 2:
            return idz.T('march');
          case 3:
            return idz.T('april');
          case 4:
            return idz.T('may');
          case 5:
            return idz.T('june');
          case 6:
            return idz.T('july');
          case 7:
            return idz.T('august');
          case 8:
            return idz.T('september');
          case 9:
            return idz.T('october');
          case 10:
            return idz.T('november');
          case 11:
            return idz.T('december');
          default:
            return '';
        }
      },

      /*____________________________________________________________________________________________________________________________________________________________________________________________________*/
      getDayName(date) {
        if (!date) {
          return '';
        }

        const day = date.getDay();

        switch (day) {
          case 0:
            return idz.T('sunday');
          case 1:
            return idz.T('monday');
          case 2:
            return idz.T('tuesday');
          case 3:
            return idz.T('wednesday');
          case 4:
            return idz.T('thursday');
          case 5:
            return idz.T('friday');
          case 6:
            return idz.T('saturday');
          default:
            return '';
        }
      },

      /*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
      formatDate(date, pattern) {
        const monthName = this.getMonthName(date);
        const dayName = this.getDayName(date);

        return pattern
          .replace(/yyyy/g, date.getFullYear())
          .replace(/yy/g, String(date.getFullYear()).substr(2, 2))
          .replace(/MMM/g, monthName.substr(0, 3).toUpperCase())
          .replace(/Mmm/g, monthName.substr(0, 3))
          .replace(/MM\*/g, monthName.toUpperCase())
          .replace(/Mm\*/g, monthName)
          .replace(
            /mm/g,
            idz.util.string.padLeft(String(date.getMonth() + 1), '0', 2),
          )
          .replace(/DDD/g, dayName.substr(0, 3).toUpperCase())
          .replace(/Ddd/g, dayName.substr(0, 3))
          .replace(/DD\*/g, dayName.toUpperCase())
          .replace(/Dd\*/g, dayName)
          .replace(
            /dd/g,
            idz.util.string.padLeft(String(date.getDate()), '0', 2),
          )
          .replace(/d\*/g, date.getDate())
          .replace(
            /hh/g,
            idz.util.string.padLeft(String(date.getHours()), '0', 2),
          )
          .replace(
            /ii/g,
            idz.util.string.padLeft(String(date.getMinutes()), '0', 2),
          );
      },
    },
  };
}
