export type Thread = {
  queueNext: (fn: () => Promise<void>) => Promise<void>;
};
export default (): Thread => {
  //________________________________________________________________________________________________________
  let thread: Promise<void> = Promise.resolve();

  /*____________________________________________________________________________________________________________________________________________________________________________*/
  const queueNext = (fn: () => Promise<void>) => {
    thread = thread.then(fn);
    return thread;
  };

  return {
    queueNext,
  };
};
