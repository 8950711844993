export default (idz) => {
  /*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  async function fetchConversationStarters(rule) {
    const { notificationId } = rule.targetingActions[0];
    const notification = idz.proactifData.notifications[notificationId];

    const fetchStarters = await import(
      /*_________________________________________*/ '../../../model/rule/action/actions/fetchStarters'
    ).then((m) => m.default);
    const starters = await fetchStarters(
      notification.templateAttributes.starters,
      idz.chaturl,
      idz.website_id,
      idz.curlang.toUpperCase(),
      notification.id,
      notification.templateAttributes.knowledgeId,
      notification.templateAttributes.knowledgeCustomDataKey,
    );
    return starters;
  }

  return {
    fetchConversationStarters,
  };
};
