import getChannel from '../../../channels';

const displayButtonDispatcherFactory = (dispatcher) => ({
  /*__________________________________________________________________________________________________________________________________________________________________________*/
  computeAvailabilityAndDispatchDisplay(availability, actionButtonChannels) {
    const computedAvailability = actionButtonChannels.reduce(
      (acc, actionChannel) => ({
        ...acc,
        [actionChannel]: getChannel(actionChannel).isAvailable(availability),
      }),
      {},
    );
    this.dispatchDisplay(computedAvailability);

    return computedAvailability;
  },

  dispatchDisplay(computedAvailability) {
    if (computedAvailability.chat) {
      dispatcher.emit('ChatButtonDisplayed');
    }

    if (computedAvailability.call) {
      dispatcher.emit('CallButtonDisplayed');
    }

    if (computedAvailability.visio) {
      dispatcher.emit('VideoButtonDisplayed');
    }
  },
});

export default displayButtonDispatcherFactory;
