import {
  getIdz,
  getEventManager,
  isChatting as getIsChatting,
} from '../../../../../../../shared/globals';
import AbstractAction from '../AbstractAction';
import getChannel from '../../../../channels';
import botScenarioPrefetcherFactory, {
  isPrefetchableRuleFactory,
} from '../botScenarioPrefetcher';
import { getNotificationById } from '../notifications';
import { redirectToMessenger } from '../../../../../../../notificationOrchestrator/offsiteManagers/messengerManager';
import { redirectToSMS } from '../../../../../../../notificationOrchestrator/offsiteManagers/smsManager';
import { redirectToWhatsapp } from '../../../../../../../notificationOrchestrator/offsiteManagers/whatsappManager';
import displayButtonDispatcherFactory from '../displayButtonDispatcher';
import omit from '../../../../../../../shared/utils/omit';
import noop from '../../../../../../../shared/utils/noop';
import debounce from '../../../../../../../shared/utils/debounce';
import getProperty from '../../../../../../../shared/utils/getProperty';
import {
  fixedButtonStateUpdates,
  fixedButtonStore,
  getIsChannelConversationOngoing,
} from './fixedButtonStore';
import retrier from '../../../../../../../shared/utils/retrier';
import loadTranslations from '../../../../../../../live/src/i18n/loadTranslations';
import * as fixedButtonHelpers from './fixedButtonHelpers';

export const isFixedButtonAction = (actionProperties) =>
  actionProperties.type === 'templated_notification' &&
  actionProperties.parameters.type === 'FIXED';

export const isFixedButtonRule = (rule) =>
  rule.targetingActions.length > 0 &&
  isFixedButtonAction(rule.targetingActions[0]);

export const isEmbeddedConversationStarterAction = (actionProperties) =>
  actionProperties.type === 'templated_notification' &&
  actionProperties.parameters.type === 'EMBEDDED_CONVERSATION_STARTER';

export const isEmbeddedConversationStarterRule = (rule) =>
  rule.targetingActions?.[0]?.parameters?.type ===
  'EMBEDDED_CONVERSATION_STARTER';

const getActionButtonOldLivechatChannel = (button) => {
  return button.channel === 'VIDEO' ? 'visio' : button.channel.toLowerCase();
};

export default () => {
  const idz = getIdz();
  const eventManager = getEventManager();

  const botScenarioPrefetcher = botScenarioPrefetcherFactory(idz);
  const isPrefetchableRule = isPrefetchableRuleFactory(idz);

  const onClickPerChannel = {
    chat: (event) =>
      eventManager.emit(
        'engagement.showChatboxButtonClickedBeforeBundle',
        omit(event, 'offsiteProfileExternalId'),
      ),
    call: (event) =>
      eventManager.emit(
        'engagement.showCallButtonClickedBeforeBundle',
        omit(event, 'offsiteProfileExternalId'),
      ),
    video: (event) =>
      eventManager.emit(
        'conversations.visio.requestedBeforeBundle',
        omit(event, 'offsiteProfileExternalId'),
      ),
    visio: (event) =>
      eventManager.emit(
        'conversations.visio.requestedBeforeBundle',
        omit(event, 'offsiteProfileExternalId'),
      ),
    messenger: ({ offsiteProfileExternalId }) =>
      redirectToMessenger(offsiteProfileExternalId),
    sms: ({ offsiteProfileExternalId }) =>
      redirectToSMS(offsiteProfileExternalId),
    whatsapp: ({ offsiteProfileExternalId }) =>
      redirectToWhatsapp(offsiteProfileExternalId),
  };

  const mapOptionHtmlElement = (cssSelector, fn) => {
    if (!cssSelector) {
      return null;
    }

    const element = document.querySelector(cssSelector);

    return element ? fn(element) : null;
  };

  const updateDOMSelectors = (
    channel,
    channelType,
    onlineSelector,
    busySelector,
    offlineSelector,
  ) => {
    if (channel === channelType) {
      fixedButtonHelpers.show(onlineSelector);
      fixedButtonHelpers.hide(busySelector);
      fixedButtonHelpers.hide(offlineSelector);
    } else {
      fixedButtonHelpers.hide(onlineSelector);
      fixedButtonHelpers.hide(busySelector);
      fixedButtonHelpers.show(offlineSelector);
    }
  };

  const getTargetingIds = (notificationId) =>
    idz.proactifData?.interactionRules
      .filter((rule) =>
        rule.targetingActions.some(
          (action) => action.notificationId === notificationId,
        ),
      )
      .map((rule) => rule.id);

  /*_________________________________________________________________________________________________________________________________________*/
  class FixedButtonAction extends AbstractAction {
    constructor(properties) {
      super(properties);
      this.notification = getNotificationById(this.notificationId);
      const channelType = this.parameters.button.channel.toLowerCase();

      this.selectors = this.notification.templateAttributes;
      this.channel = getChannel(channelType);
      this.onClickForChannel = getProperty(
        onClickPerChannel,
        channelType,
        noop,
      );
      this.offsiteProfileExternalId =
        this.parameters.button.offsiteProfileExternalId;
      this.domElements = this.getDomElements();
      this.fixedButtonStateUpdates = fixedButtonStateUpdates({
        idz,
        busySelector: this.selectors.busyCssSelector,
        offlineSelector: this.selectors.offlineCssSelector,
        onlineSelector: this.selectors.onlineCssSelector,
        channelType,
        targetingIds: getTargetingIds(this.notificationId) || [],
      });

      ['call.operatorAnswered', 'call.started'].forEach((event) =>
        eventManager.on(event, () => {
          updateDOMSelectors(
            'call',
            channelType,
            this.selectors.onlineCssSelector,
            this.selectors.busyCssSelector,
            this.selectors.offlineCssSelector,
          );
        }),
      );

      eventManager.on('conversation.chat.started', () => {
        updateDOMSelectors(
          'chat',
          channelType,
          this.selectors.onlineCssSelector,
          this.selectors.busyCssSelector,
          this.selectors.offlineCssSelector,
        );
      });
      eventManager.on('conversation.video.routing.completed', () => {
        updateDOMSelectors(
          'video',
          channelType,
          this.selectors.onlineCssSelector,
          this.selectors.busyCssSelector,
          this.selectors.offlineCssSelector,
        );
      });
    }

    static canHandle = isFixedButtonAction;

    getDomElements() {
      return fixedButtonHelpers.selectAll(this.selectors.onlineCssSelector);
    }

    cancelStaleElements() {
      const currentDomElements = this.getDomElements();
      const hasStaleElements = currentDomElements.reduce(
        (staleness, domElement, index) =>
          staleness || domElement !== this.domElements[index],
        false,
      );
      if (hasStaleElements) {
        this.cancel();
      }
    }

    isAvailable(availability) {
      return this.channel.isAvailable(availability);
    }

    shouldExecute() {
      //________________________________________________________________________
      return true;
    }

    shouldCancel() {
      //_________________________________________________________________
      return false;
    }

    isOnlineButtonInDom() {
      return (
        this.selectors.onlineCssSelector &&
        document.querySelector(this.selectors.onlineCssSelector) !== null
      );
    }

    isBusyButtonInDom() {
      return (
        this.selectors.busyCssSelector &&
        document.querySelector(this.selectors.busyCssSelector) !== null
      );
    }

    isOfflineButtonInDom() {
      return (
        this.selectors.offlineCssSelector &&
        document.querySelector(this.selectors.offlineCssSelector) !== null
      );
    }

    canOnlineStateBeDisplayed(availability) {
      return (
        this.channel.isAvailable(availability) && this.isOnlineButtonInDom()
      );
    }

    canBusyStateBeDisplayed(availability) {
      return this.channel.isBusy(availability) && this.isBusyButtonInDom();
    }

    canOfflineStateBeDisplayed(availability) {
      return (
        !this.channel.isBusy(availability) &&
        !this.channel.isAvailable(availability) &&
        this.isOfflineButtonInDom()
      );
    }

    canBeDisplayed(availability) {
      return (
        this.canOnlineStateBeDisplayed(availability) ||
        this.canBusyStateBeDisplayed(availability) ||
        this.canOfflineStateBeDisplayed(availability)
      );
    }

    execute(
      availability,
      ruleId,
      engagementRuleId,
      routingRuleId,
      channelType,
    ) {
      let isExecuted = this.isExecuted();
      if (isExecuted) {
        this.cancelStaleElements();
        isExecuted = this.isExecuted();
      }

      super.execute(availability, ruleId, engagementRuleId, routingRuleId);

      //______________________________________________________________
      const { selectors } = this;

      //_____________________________________________
      //____________________________________________
      //_____________________________________
      if (!isExecuted) {
        this.selectorsInitialStyle = {
          online: mapOptionHtmlElement(
            this.selectors.onlineCssSelector,
            (elem) => elem.style.cssText,
          ),
          busy: mapOptionHtmlElement(
            this.selectors.busyCssSelector,
            (elem) => elem.style.cssText,
          ),
          offline: mapOptionHtmlElement(
            this.selectors.offlineCssSelector,
            (elem) => elem.style.cssText,
          ),
        };
      }

      fixedButtonHelpers.hide(selectors.onlineCssSelector);
      fixedButtonHelpers.hide(selectors.busyCssSelector);
      fixedButtonHelpers.hide(selectors.offlineCssSelector);

      //________________________________________________________
      if (!isExecuted) {
        this.onHover = () => {
          eventManager.emit('notification.hover', {
            action: this,
            isHover: true,
          });
        };
        this.outHover = () => {
          eventManager.emit('notification.hover', {
            action: this,
            isHover: false,
          });
        };
        this.onClick = debounce(async (e) => {
          const { nodeName, dataset, innerText } = e.target;

          if (nodeName === 'INPUT' || dataset.idzInert !== undefined) {
            return false;
          }

          const idzFirstMessageContent = dataset.idzFirstMessage;

          //________________________________________________________________________
          const isFirstMessageButton = idzFirstMessageContent !== undefined;
          //__________________________________________________________________________
          const hasInputTarget =
            isFirstMessageButton && idzFirstMessageContent !== '';
          const firstMessage = hasInputTarget
            ? document.querySelector(idzFirstMessageContent).value
            : isFirstMessageButton && innerText;

          if (hasInputTarget) {
            document.querySelector(idzFirstMessageContent).value = '';
          }

          //_______________________________________________________________________________
          //_______________________________________________
          if (firstMessage && firstMessage !== '') {
            fixedButtonStore.dispatch('firstVisitorMessage', firstMessage);
            //___________________________________________________________________________________________________________
            setTimeout(
              () => fixedButtonStore.dispatch('firstVisitorMessage', null),
              200,
            );
          }

          if (!hasInputTarget || (firstMessage && firstMessage !== '')) {
            this.engage();
          }

          return false;
        }, 200);

        this.keyUp = (e) => {
          const { target, keyCode } = e;
          //_______________________________________
          if (target.nodeName !== 'INPUT') {
            return false;
          }
          //_____________
          if (keyCode === 13 && target.value && target.value.trim() !== '') {
            fixedButtonStore.dispatch('firstVisitorMessage', target.value);
            target.value = '';
            this.engage();
          }
          //______________________________________
          return false;
        };

        this.engage = async () => {
          //________________________
          eventManager.emit('notification.clicked', {
            action: this,
            engagementRuleId,
            channel: channelType,
            ruleId,
          });

          const isPrefetchCase = !!(
            routingRuleId && isPrefetchableRule(ruleId, routingRuleId)
          );

          super.doSideEffectsOnClick(ruleId);
          await loadTranslations(idz);
          this.onClickForChannel({
            vuid: idz.vuid,
            id: this.id,
            ruleId,
            routingRuleId,
            isPrefetchCase,
            offsiteProfileExternalId: this.offsiteProfileExternalId,
          });

          if (isPrefetchCase && !getIsChatting()) {
            retrier(
              () => botScenarioPrefetcher(ruleId, routingRuleId),
              1,
              50,
            ).then(
              ({ data: messages }) => {
                const event = {
                  routingRuleId,
                  ruleId,
                  messages,
                  vuid: idz.vuid,
                };
                eventManager.emit(
                  'notification.showChatboxWithPrefetchedScenario',
                  event,
                );
              },
              () => {
                const event = {
                  routingRuleId,
                  ruleId,
                  vuid: idz.vuid,
                };
                eventManager.emit(
                  'notification.showChatboxWithPrefetchedScenarioFailed',
                  event,
                );
              },
            );
          }
          return false;
        };

        fixedButtonHelpers.on(
          selectors.onlineCssSelector,
          'click',
          this.onClick,
        );
        fixedButtonHelpers.on(selectors.onlineCssSelector, 'keyup', this.keyUp);
        fixedButtonHelpers.on(
          selectors.onlineCssSelector,
          'mouseover',
          this.onHover,
        );
        fixedButtonHelpers.on(
          selectors.onlineCssSelector,
          'mouseout',
          this.outHover,
        );
        fixedButtonHelpers.on(
          selectors.onlineCssSelector,
          'focus',
          this.onHover,
        );
        fixedButtonHelpers.on(
          selectors.onlineCssSelector,
          'blur',
          this.outHover,
        );
        this.fixedButtonStateUpdates.listen();
      }
      const isOngoingConversation = getIsChatting();
      const isChannelOngoing =
        channelType !== undefined &&
        getIsChannelConversationOngoing(channelType);

      if (
        (this.isAvailable(availability) && !isOngoingConversation) ||
        isChannelOngoing
      ) {
        fixedButtonHelpers.show(selectors.onlineCssSelector);
      } else if (this.channel.isBusy(availability) && !isOngoingConversation) {
        fixedButtonHelpers.show(selectors.busyCssSelector);
      } else {
        fixedButtonHelpers.show(selectors.offlineCssSelector);
        //_________________________________
        //________________________________________________________________
        if (selectors.offlineCssSelector !== selectors.busyCssSelector) {
          fixedButtonHelpers.hide(selectors.busyCssSelector);
        }
        fixedButtonHelpers.hide(selectors.onlineCssSelector);
      }

      const actionButtonChannel = getActionButtonOldLivechatChannel(
        this.parameters.button,
      );
      const displayButtonDispatcher =
        displayButtonDispatcherFactory(eventManager);

      displayButtonDispatcher.computeAvailabilityAndDispatchDisplay(
        availability,
        [actionButtonChannel],
      );
    }

    cancel() {
      super.cancel();

      //______________________________________________________________
      const { selectors, selectorsInitialStyle } = this;

      mapOptionHtmlElement(this.selectors.onlineCssSelector, (elem) => {
        elem.style.cssText = selectorsInitialStyle.online; //__________________________________________
      });
      mapOptionHtmlElement(this.selectors.busyCssSelector, (elem) => {
        elem.style.cssText = selectorsInitialStyle.busy; //__________________________________________
      });
      mapOptionHtmlElement(this.selectors.offlineCssSelector, (elem) => {
        elem.style.cssText = selectorsInitialStyle.offline; //__________________________________________
      });

      //_________________________
      fixedButtonHelpers.off(
        selectors.onlineCssSelector,
        'click',
        this.onClick,
      );
      fixedButtonHelpers.off(selectors.onlineCssSelector, 'keyup', this.keyUp);
      fixedButtonHelpers.off(
        selectors.onlineCssSelector,
        'mouseover',
        this.onHover,
      );
      fixedButtonHelpers.off(
        selectors.onlineCssSelector,
        'mouseout',
        this.offHover,
      );
      fixedButtonHelpers.off(
        selectors.onlineCssSelector,
        'focus',
        this.offHover,
      );
      fixedButtonHelpers.off(
        selectors.onlineCssSelector,
        'blur',
        this.offHover,
      );
      this.fixedButtonStateUpdates.stopListening();
    }
  }

  FixedButtonAction.prototype.visible = true;

  return FixedButtonAction;
};
