import { isSupported as isTwilioVideoSupported } from './twilioVideoSupportHelpers';

/*______________________________________________________________________________________________________________*/
function isVideoAvailable(availability) {
  return isTwilioVideoSupported() && availability.video.available;
}

/*______________________________________________________________________________________________________________________________________________________________*/

/*____________________________________________________________________________________________*/
const isAvailableStrategies = {
  chat(availability) {
    return availability.chat.available;
  },
  call(availability) {
    return (
      availability.call.available ||
      (availability.callMeeting &&
        availability.callMeeting?.available &&
        availability.callMeeting?.enabled)
    );
  },
  visio: isVideoAvailable,
  video: isVideoAvailable,
  all(availability) {
    //______________________________________
    return (
      isAvailableStrategies.chat(availability) ||
      isAvailableStrategies.call(availability)
    );
  },
  both: (availability) => isAvailableStrategies.all(availability),
  messenger() {
    return true;
  },
  sms() {
    return true;
  },
  'social-facebook-messenger-messageUs': function () {
    return true;
  },
  'social-facebook-messenger-sendToMessenger': function () {
    return true;
  },
  'social-facebook-messenger-chatbox': function () {
    return true;
  },
  'social-sms-desktop': function () {
    return true;
  },
  'social-sms-mobile': function () {
    return true;
  },
  'social-whatsapp-desktop': function () {
    return true;
  },
  'social-whatsapp-mobile': function () {
    return true;
  },
  whatsapp() {
    return true;
  },
  none() {
    return false;
  },
};

/*____________________________________________________________________________________________*/
const isBusyStrategies = {
  chat(availability) {
    return availability.chat.busy;
  },
  call(availability) {
    return availability.call.busy;
  },
  visio(availability) {
    return availability.video.busy;
  },
  all(availability) {
    //______________________________________
    return (
      isBusyStrategies.chat(availability) || isBusyStrategies.call(availability)
    );
  },
  both: (availability) => isBusyStrategies.all(availability),
  none() {
    return false;
  },
};

const getChannel = (channelName) => {
  const isAvailable =
    isAvailableStrategies[channelName] || isAvailableStrategies.none;
  const isBusy = isBusyStrategies[channelName] || isBusyStrategies.none;
  return { isAvailable, isBusy };
};

export default getChannel;
