import { getIdz } from '../../../shared/globals';

const featureFlag = 'iadvize.engagement.notification-display-capping';

const getCappingCounter = (): number =>
  Number(getIdz().flags.notificationCappingCounter) || 0;

const getCappingLimit = (): number => getIdz().featureFlags[featureFlag] || -1;

export function incrementNotificationCappingIfNecessary() {
  const cappingLimit = getCappingLimit();

  if (cappingLimit !== -1) {
    const cappingCounter = getCappingCounter();
    getIdz().JSONStore.save({ notificationCappingCounter: cappingCounter + 1 });
    getIdz().flags.notificationCappingCounter = cappingCounter + 1;
  }
}

export function isNotificationDisplayCapped() {
  const cappingLimit = getCappingLimit();
  if (cappingLimit !== -1) {
    const cappingCounter = getCappingCounter();
    return cappingCounter >= cappingLimit;
  }
  return false;
}
