import flatten from '../../../shared/utils/flatten';
import noop from '../../../shared/utils/noop';
import partition from '../../../shared/utils/partition';

//_________________________________________________________________________________________
//__________________________________________________________________________________________
const visibleIntersectionRatio = 0;

const intersection = (arr, ...args) =>
  arr.filter((item) => args.every((_arr) => _arr.includes(item)));

let fixedButtonVisibilityObserver;

export const createFixedButtonVisibilityObserver = () => {
  if (typeof window.IntersectionObserver !== 'function') {
    return {
      onceVisible: (action, callback) => callback(),
      unsubscribeAll: noop,
    };
  }

  let subscriptions = [];

  const intersectionObserver = new IntersectionObserver(
    (entries) => {
      const visibleTargets = entries
        .filter((entry) => entry.isIntersecting || entry.isVisible)
        .map((entry) => entry.target);

      if (visibleTargets.length > 0) {
        const [matchingSubscriptions, remainingSubscriptions] = partition(
          subscriptions,
          ({ elements }) => intersection(elements, visibleTargets).length > 0,
        );

        subscriptions = remainingSubscriptions;

        flatten(matchingSubscriptions.map(({ elements }) => elements)).forEach(
          (element) => intersectionObserver.unobserve(element),
        );
        matchingSubscriptions.forEach(({ callback }) => callback());
      }
    },
    { threshold: visibleIntersectionRatio },
  );

  const isElementNotHidden = (element) =>
    window.getComputedStyle(element).display !== 'none';

  const onceVisible = (selectors, callback) => {
    const elements = selectors
      .filter(Boolean)
      .map((selector) => {
        try {
          return Array.from(document.querySelectorAll(selector));
        } catch (e) {
          return [];
        }
      })
      .filter((oElement) => Array.isArray(oElement) && oElement.length > 0)
      .flat();

    if (elements.filter(isElementNotHidden).length > 0) {
      subscriptions = [...subscriptions, { elements, callback }];
      elements.forEach((element) => intersectionObserver.observe(element));
    } else if (elements.length > 0) {
      const parents = elements.map((element) => element.parentNode);

      const elementsWithParent = [...elements, ...parents];
      subscriptions = [
        ...subscriptions,
        { elements: elementsWithParent, callback },
      ];
      elementsWithParent.forEach((element) =>
        intersectionObserver.observe(element),
      );
    }
  };

  const unsubscribeAll = () => {
    subscriptions = [];
    intersectionObserver.disconnect();
  };

  fixedButtonVisibilityObserver = {
    onceVisible,
    unsubscribeAll,
  };

  return fixedButtonVisibilityObserver;
};

export const getFixedButtonVisibilityObserver = () =>
  fixedButtonVisibilityObserver;
