import { getIdz } from '../../shared/globals';

import registerUtil from './util/Util';
import getJSONStore from '../../shared/JSONStore';
import Store from '../../shared/Store';

import {
  startPerformanceMeasure,
  PerformanceMarks,
} from '../../../lib/performance';

export default () => {
  const idz = getIdz();
  startPerformanceMeasure(PerformanceMarks.TARGETING_LOAD);

  //_________________________________________________________________________
  //__________________________________________________________
  //______________________________________________________________________________________________

  registerUtil(window, idz);

  idz.Store = Store;
  idz.JSONStore = getJSONStore();
};
