import { TimeCondition } from '../model/rule/condition/types';
import { OPERATORS } from '../model/rule/condition/operators';

let scheduledRules = {};

const schedulableOperators = [
  OPERATORS.greaterThan,
  OPERATORS.greaterOrEqualThan,
];
const reduceNextScheduleTime = (conditions) =>
  conditions?.reduce((res, condition) => {
    if (
      condition instanceof TimeCondition &&
      schedulableOperators?.includes(condition.logicalOperator)
    ) {
      const conditionNextScheduleTime =
        condition.getTestValue() - condition.getValue();

      return Math.max(res, conditionNextScheduleTime);
    }

    return res;
  }, -1);

/*_________________________________________________________________________________________________________________________________________________________________________________*/
export const scheduleRuleRunIfNeeded = (rule, run) => {
  const nextScheduleTime = reduceNextScheduleTime(rule.conditions);

  if (nextScheduleTime >= 0) {
    scheduledRules[rule.id] = setTimeout(() => {
      run();
      delete scheduledRules[rule.id];
    }, nextScheduleTime);
  }
};

/*____________________________________________________________________*/
export const clear = () => {
  Object.values(scheduledRules).forEach((timeoutId) => clearTimeout(timeoutId));
  scheduledRules = {};
};
