import flow from '../../../shared/utils/flow';
import identity from '../../../shared/utils/identity';

/*________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const isRuleConditionMet = (condition, ruleId) => {
  if (typeof condition.getValue() !== 'undefined') {
    if (condition.tRefreshData instanceof Function) {
      //__________________________________
      condition.tRefreshData();
    }

    if (condition.isMet()) {
      return true;
    }

    if (condition.tIsFalse instanceof Function) {
      condition.tIsFalse(ruleId);
    }
  }

  return false;
};

/*_______________________________________________________________________________________________________________________________________________*/
export const areRuleConditionsMet = (rule) => {
  //_____________________________________________________________
  //_____________________________________________
  //________________________________________________
  return flow(
    (conditions) => conditions.filter((condition) => condition.criteria),

    (conditionsWithCriteria) =>
      conditionsWithCriteria.map((condition) =>
        isRuleConditionMet(condition, rule.id),
      ),

    (rules) => rules.every(identity),
  )(rule.conditions);
};
