import AbstractCondition from './AbstractCondition';
import conditionTypes from './types';

/*___________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export default function createCondition(data) {
  const criteria = data.criteria || {};
  let typeName = criteria.type;

  //___________________________________________________________________
  if (criteria.valueType && criteria.valueType === 'count') {
    typeName = 'INTEGER';
  }

  const TypedCondition = conditionTypes[typeName] || AbstractCondition;

  return new TypedCondition(data);
}
