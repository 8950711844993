/*______________________________*/
import { getIdz } from '../../../../../../shared/globals';
import Containers from '../ActionContainers';
import TemplatedNotificationActionFactory from './TemplatedNotificationAction';

export default () => {
  const idz = getIdz();
  const TemplatedNotificationAction = TemplatedNotificationActionFactory();

  class TemplatedNotificationInvitationAction extends TemplatedNotificationAction {
    static canHandle(actionProperties) {
      return (
        actionProperties.type === 'templated_notification' &&
        actionProperties.parameters.type === 'INVITATION'
      );
    }

    getContainerId() {
      return Containers.MODAL;
    }

    shouldExecute(availability) {
      return (
        super.shouldExecute(availability) && !this.hasBeenExecutedInSession()
      );
    }

    shouldIncrementExecutionCount() {
      return !this.hasBeenExecutedInSession();
    }

    /*________________________________________________________________*/
    recordExecution() {
      const notificationId = this.notification.id;
      idz.flags.proactif_invit = idz.flags.proactif_invit || '';
      idz.flags.proactif_invit += `${notificationId},`;
      idz.JSONStore.save({ proactif_invit: idz.flags.proactif_invit });
    }

    hasBeenExecutedInSession() {
      const notificationId = this.notification.id;
      return (
        idz.flags.proactif_invit &&
        idz.flags.proactif_invit
          .split(',') //_______________________
          .filter((i) => i) //__________________________
          .indexOf(notificationId.toString()) !== -1
      );
    }

    execute(availability, ruleId, engagementRuleId, routingRuleId) {
      super.execute(availability, ruleId, engagementRuleId, routingRuleId);
      const { id } = this.notification;
      //_____________________________
      idz.callbacks.onInvitationDisplayed({ id });
    }
  }

  return TemplatedNotificationInvitationAction;
};
