const isNil = (val) => val == null;

export const OPERATORS = {
  is: 'is',
  notIs: 'notIs',

  //___________
  contains: 'contains',
  notContains: 'notContains',
  endsWith: 'endsWith',
  beginsWith: 'beginsWith',

  regex: 'regex',
  notRegex: 'notRegex',

  //___________
  equal: 'equal',
  lowerThan: 'lowerThan',
  greaterThan: 'greaterThan',
  lowerOrEqualThan: 'lowerOrEqualThan',
  greaterOrEqualThan: 'greaterOrEqualThan',
};

/*_______________________________________________________________________________________________________________________________________________*/
const operators = {
  [OPERATORS.is]: (value, test) => !isNil(value) && value == test, //_______________________________
  [OPERATORS.notIs]: (value, test) => !operators[OPERATORS.is](value, test),

  //___________
  [OPERATORS.contains]: (value, test) =>
    !isNil(value) && value.indexOf(test) !== -1,
  [OPERATORS.notContains]: (value, test) =>
    !operators[OPERATORS.contains](value, test),
  [OPERATORS.endsWith]: (value = '', test) => value.endsWith(test),
  [OPERATORS.beginsWith]: (value = '', test) => value.startsWith(test),

  [OPERATORS.regex]: (value, test) => {
    try {
      return new RegExp(test).test(value);
    } catch (e) {
      return false;
    }
  },
  [OPERATORS.notRegex]: (value, test) => {
    try {
      return !new RegExp(test).test(value);
    } catch (e) {
      return false;
    }
  },

  //___________
  [OPERATORS.equal]: (value, test) => !isNil(value) && value == test, //_______________________________
  [OPERATORS.lowerThan]: (value, test) => !isNil(value) && value < test,
  [OPERATORS.greaterThan]: (value, test) => !isNil(value) && value > test,
  [OPERATORS.lowerOrEqualThan]: (value, test) => !isNil(value) && value <= test,
  [OPERATORS.greaterOrEqualThan]: (value, test) =>
    !isNil(value) && value >= test,
};

export default operators;
