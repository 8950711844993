type ValueOf<T> = T[keyof T];

export default function getProperty<T>(
  obj: T,
  key: keyof T,
  defaultValue?: ValueOf<T>,
): ValueOf<T> | undefined {
  if (!obj || !obj[key]) return defaultValue;
  return obj[key];
}
