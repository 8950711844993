const states = {
  VALID: 'VALID',
  CANCELED: 'CANCELED',
};

/*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export default (activeTabCheckInterval = 1000) => {
  class CancelableFactory {
    constructor() {
      this.state = states.VALID;
    }

    wrapInWaitingActiveTabCancelable(fn) {
      return (...args) =>
        new Promise((resolve, reject) => {
          const check = () => {
            if (this.state === states.CANCELED) {
              resolve();
            } else if (
              document.visibilityState &&
              document.visibilityState === 'hidden'
            ) {
              setTimeout(check, activeTabCheckInterval);
            } else {
              const res = fn(...args);
              if (res instanceof Promise) {
                res.then(resolve, reject);
              } else {
                resolve(res);
              }
            }
          };
          check();
        });
    }
  }

  let cancelableFactory = new CancelableFactory();

  /*_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const wrapInWaitingActiveTabCancelable = (fn) =>
    cancelableFactory.wrapInWaitingActiveTabCancelable(fn);

  /*_______________________________________________*/
  const cancelAll = () => {
    cancelableFactory.state = states.CANCELED;
    cancelableFactory = new CancelableFactory();
  };

  return {
    wrapInWaitingActiveTabCancelable,
    cancelAll,
  };
};
