/*_______________________________________________________*/
import { Translations } from '@iadvize/translations-library';
import { BooleanAsString, ConversationJID } from './utils';
import { ClickedUrlHandler } from '../../entry/publicMethods/handleClickedUrl/types';
import { DynamicConfig, VisitorProfile } from './dynamicConfig';
import { OrchestratorChannel } from '../../orchestrator/src/orchestratorStore/channel';
import { IAdvizeCallbacks } from './callbacks';
import { IAdvizeChat } from './chat';
import { StaticConfig } from '../../entry/types';
import { Transaction } from '../../live/src/transaction/types';
import set from '../../entry/publicMethods/set/set';
import get from '../../entry/publicMethods/get/get';
import off from '../../entry/publicMethods/off/off';
import on from '../../entry/publicMethods/on/on';
import { AMDModules } from '../../live/src/amd/amd';
import { CommonConfig } from '../../entry/visitorContextConfig/types';

export type IdzVStats = VisitorProfile & {
  /*______________________________*/
  customData: () => string;

  /*__________________________________________________________*/
  getProfile: () => string;
};

export enum PresenceType {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  DND = 'dnd',
}

export const MessageTypes = {
  OPERATOR: 1,
  VISITOR: 2,
  OPERATOR_ALERT: 3,
  VISITOR_ALERT: 4,
  VISITOR_NAVIGATION: 5,
  OPERATOR_RICH_CONTENT: 6,
  VISITOR_RICH_CONTENT: 7,
} as const;

export type MessageWithOptionalId = {
  id?: string;
  message: string;
};

export type LegacyOperator = {
  /*___________________________________*/
  id: string;

  /*_____________________________________________________*/
  barejid: string;

  /*________________________________________________________*/
  nick: string;

  pseudo: string;
  avatar: string;
  role: string; //__________________
  client_id?: number;

  externalId?: string;
};

export type CallOperator = {
  /*___________________________________*/
  id: string;

  /*___________________________________________________*/
  barejid?: string;

  /*________________________________________________________*/
  nick: string;

  pseudo: string;
  avatar: string;
  role: string;
  client_id: string;

  c2c_phone: string; //_________________________
  curchat: string; //______________________
  direct_phone: string;
  is_online: BooleanAsString;
  languages: string; //_____________________
  maxchat: string; //______________________
  messenger_email: string;
  messenger_isonline: BooleanAsString;
  on_call: BooleanAsString;
  on_pupitre: BooleanAsString;
  phone_facturation: string;
};

type CustomDataConfig = {
  id: string;
  field: string;
  label: string;
  path: string;
  type: string;
  val: string;
  valtype: string;
  visible_in_desk: string;
};

export type GlobalIdz = CommonConfig &
  StaticConfig['config'] &
  DynamicConfig &
  PublicMethods &
  AMDModules & {
    /*______________________________________________________________________________________________*/
    blocked: boolean;

    vStats: IdzVStats;

    /*____________________________________________*/
    c2cTimeout: number;
    activeCall: string | null;

    /*____________________________________________________________________________________________________*/
    conversationJid: ConversationJID;

    /*_______________________________________________________________________________________________*/
    visitorNick: string | undefined;

    chat: IAdvizeChat;

    trad: Translations;

    customizeTranslate: {
      [key: string]: {
        [lang: string]: string;
      };
    };

    /*_____________________________________________________________________________________________*/
    setVisitorCookiesConsent: (
      hasConsent: boolean,
      expiration: number,
      callback?: () => void,
    ) => void;

    /*___________________________________________*/
    setVisitorGdprConsent: (hasConsented: boolean) => void;

    /*____________________________________*/
    navigate: (newLocation: string) => void;

    util: {
      date: { formatDate: Function };
      nl2br: (message: string) => string;
      htmlspecialchars: (message: string) => string;
    };

    JSONStore: {
      save: (object: Object) => Promise<void>;
      saveData: (object: Object) => Promise<void>;
    };

    Store: {
      get: (key: string) => Object | undefined;
    };

    /*________________________________*/
    operator: LegacyOperator | CallOperator | {};

    /*________________________________*/
    c2coperator: CallOperator | {};

    ringing: boolean;

    callbacks: IAdvizeCallbacks;

    orchestrator: OrchestratorChannel;

    platform: string;
    urls: {
      buildHttpUrl: (path: string) => string;
      buildWsUrl: (path: string) => string;
    };

    /*________________________*/
    mirrorHost: string;

    /*______________________________*/
    graphql: {
      url: string;
    };

    /*_________________________*/
    T: (translationKey: keyof Translations) => string;

    version: string;

    ProActif: {
      run: () => void;
      _datas: {
        id: number;
        routingRuleIsBot?: boolean;
        skill: { id: number };
      }[];
    };

    /*_____________________________________________________________________________________________________________________________________________________________________*/
    idzInterface: {
      callBuffer: any[];
      methods: Record<string, (...args: any[]) => void>;
    };

    Templates: Record<string, unknown>;
    templateLoadedPromise?: Promise<unknown>;
    useExplicitCookiesConsent: boolean;

    curlang: string;

    //__________________________________________
    callMeeting: {
      available?: boolean;
    };
    callInstant: {
      available?: boolean;
    };
    callOffline: {
      available?: boolean;
    };

    customDataConfig: CustomDataConfig[];
  };

export type CustomData = Partial<{
  page_type: string;
  brand: string;
  rayon: string;
  univers: string;

  cust_name: string;
  cust_firstname: string;
  cust_address: string;
  cust_city: string;
  cust_zipcode: string;
  cust_country: string;
  cust_phonenumber: string;
  cust_email: string;

  [customKeys: string]: any;
}>;

type PublicMethods = {
  activate: ReturnType<
    typeof import('../../entry/authentication/authenticationService').default
  >['activate'];
  get: typeof get;
  set: typeof set;
  handleClickedUrl: ClickedUrlHandler;
  on: typeof on;
  off: typeof off;
  logout: () => void;
  navigate: (screen: string) => void;
  recordTransaction: {
    (transaction?: Transaction): void;
    calls: { id: string; amount: number }[];
  };
  setVisitorCookiesConsent: (
    hasConsented: boolean,
    ttl?: number,
    callback?: Function,
  ) => void;
};
