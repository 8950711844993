import { getIdz, getEventManager } from '../../../../../../shared/globals';
import oldFindOpCallback from '../../../../oldFindOpCallback';
import callAvailabilities from './callAvailabilities';

/*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export const getFirstEligibleSkillIdForRules = (interactionRules = []) =>
  [...interactionRules].reverse().find((rule) => rule.skill)?.skill?.id;

/*__________________________________________________________________________________________________*/
const buildDryRunUrl = (routingRuleIds) => {
  const idz = getIdz();
  const sortedRoutingRuleIds = routingRuleIds.sort(); //_______________________________________________
  return `${idz.routing.url}routing-rules/available?platform=${
    idz.platform
  }&lang=${idz.curlang}&ids=${sortedRoutingRuleIds.join(',')}`;
};

/*______________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
const formatAvailabilities = (interactionRules) => (response) => {
  return interactionRules.map((interactionRule) => {
    const routingRuleAvailability = response.find(
      (availability) =>
        availability.routingRuleId === interactionRule.routingRuleId,
    );
    if (routingRuleAvailability) {
      return {
        id: `${interactionRule.id}`,
        availability: {
          chat: routingRuleAvailability.availabilities.chat,
          call: routingRuleAvailability.availabilities.call,
          video: routingRuleAvailability.availabilities.video,
          social: routingRuleAvailability.availabilities.thirdParties,
        },
      };
    }
    return {
      id: `${interactionRule.id}`,
      availability: {
        chat: { available: false, busy: true },
        call: { available: false, busy: true },
        video: { available: false, busy: true },
        social: { available: false, busy: true },
      },
    };
  });
};

/*_______________________________________________________________________________________________________*/
export default () => {
  const idz = getIdz();
  const eventManager = getEventManager();
  //_____________________________________________________________________
  const isPollingNeeded =
    idz.callOffline?.enabled || idz.callMeeting?.enabled || false;
  let pollingCallIsDone = !isPollingNeeded;

  //______________________________________________________________
  if (isPollingNeeded) {
    eventManager.on('targeting.routing.service.polling.reset', () => {
      pollingCallIsDone = false;
    });
  }

  /*__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  function launchDryRun(dryRunUrl, initialFindOpResult, formatFunction) {
    return fetch(dryRunUrl)
      .then((r) => r.json())
      .then(formatFunction, () => [])
      .then((ruleAvailabilities) => {
        //________________________________________________________________
        if (!pollingCallIsDone) {
          pollingCallIsDone = true;
          oldFindOpCallback(idz, initialFindOpResult);
        }
        return ruleAvailabilities;
      });
  }
  /*_____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
  const checkRulesAvailability = async (interactionRules) => {
    //_______________________________________________
    //______________________________________________
    if (interactionRules.length === 0) {
      return Promise.resolve([]);
    }

    const routingRuleIds = interactionRules.map((rule) => rule.routingRuleId);
    const filteredRoutingRuleIds = routingRuleIds.filter(Boolean); //____________________________________________________________________

    if (filteredRoutingRuleIds.length === 0) {
      //_________________________________________________________________________________________________________________________
      return Promise.resolve(formatAvailabilities(interactionRules)([]));
    }
    const uniqueRoutingRuleIds = new Set(filteredRoutingRuleIds); //___________________________________________________________
    const url = buildDryRunUrl([...uniqueRoutingRuleIds]);
    const formatFunction = formatAvailabilities(interactionRules);

    //_____________________________
    let initialFindOpResult;
    if (isPollingNeeded) {
      idz.forceDep = getFirstEligibleSkillIdForRules(interactionRules);
      initialFindOpResult = await callAvailabilities.update();
    }
    return launchDryRun(url, initialFindOpResult, formatFunction);
  };

  return {
    checkRulesAvailability,
  };
};
