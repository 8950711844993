/*_________________________________________*/
/*_________________________________________*/

import { getEventManager } from '../../../../../shared/globals';
import vStats from '../../../../../visitorService/visitorState';
import AbstractCondition from './AbstractCondition';
import { OPERATORS } from './operators';

export class BooleanCondition extends AbstractCondition {
  getBaseType() {
    return 'BOOL';
  }

  sanitizeValue(value) {
    //_________________________________________________________________
    return typeof value === 'string'
      ? value === 'true' || value === '1'
      : !!value;
  }
}

export class IntegerCondition extends AbstractCondition {
  getBaseType() {
    return 'INTEGER';
  }

  sanitizeValue(value) {
    return value === null || typeof value === 'undefined'
      ? value
      : parseInt(value, 10);
  }
}

export class FloatCondition extends AbstractCondition {
  getBaseType() {
    return 'FLOAT';
  }

  sanitizeValue(value) {
    return value === null || typeof value === 'undefined'
      ? value
      : parseFloat(value);
  }
}

export class StringCondition extends AbstractCondition {
  getBaseType() {
    return 'STRING';
  }
}

export class TimeCondition extends FloatCondition {
  constructor(data) {
    super(data);

    if (
      this.logicalOperator === OPERATORS.equal ||
      this.logicalOperator === OPERATORS.greaterThan
    ) {
      this.logicalOperator = OPERATORS.greaterOrEqualThan;
    }
  }

  /*_________________________________________________________________________________________________*/
  getBaseType() {
    return 'INTEGER';
  }

  /*__________________________________________________________________________________________________________________________________________*/
  getValue() {
    const testDurationInSeconds = Math.round(super.getTestValue() * 60);
    return new Date().getTime() - testDurationInSeconds * 1000;
  }

  /*________________________________________________________________________________________________________________________________________*/
  getTestValue() {
    return super.getValue();
  }
}

/*____________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________*/
export class ActivityCondition extends FloatCondition {
  constructor(data) {
    super(data);
    this._timeOut = null;

    //__________________________________
    this.value *= 60;
  }

  tRefreshData() {
    vStats.activityOnPage = vStats.activeTimeSpentOnPageTimer.time / 1000; //_______________
  }

  tIsFalse(ruleId) {
    //_____________________________________________________________________
    let next = 1000 + (this.value - vStats.activityOnPage) * 1000;

    if (this.logicalOperator === OPERATORS.equal) {
      this.logicalOperator = OPERATORS.greaterOrEqualThan;
    }
    if (this.logicalOperator === OPERATORS.lowerThan) {
      next -= 2000;
    }

    clearTimeout(this._timeOut);
    this._timeOut = setTimeout(function () {
      getEventManager().emit('targeting.rule.run', ruleId);
    }, Math.abs(next));
  }
}

export default {
  BOOL: BooleanCondition,
  INTEGER: IntegerCondition,
  FLOAT: FloatCondition,
  STRING: StringCondition,
  TIME: TimeCondition,
  ACTIVITY: ActivityCondition,

  //__________________
  HTML: StringCondition,
  IHTML: IntegerCondition,
  FHTML: FloatCondition,
  COUNTRY: StringCondition,
  TOWN: StringCondition,
  REFERRER: StringCondition,
  LANG: StringCondition,
  DAY: IntegerCondition,
  HOUR: IntegerCondition,
  DEVICE: StringCondition,
};
